export const numberFormat = (data: string | number) => {
  let result;
  if (typeof data === 'number') {
    result = data.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  } else {
    result = data.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }
  return result;
};

export const numberInputKeyPress = (e: any) => {
  let value = e.currentTarget.value;
  if (e.which < 40 || e.which > 57) {
    if (e.which != 46) {
      e.preventDefault();
    }
  } else {
    if (value.includes('.')) {
      if (e.which == 46) {
        e.preventDefault();
      }
    }
    if (value === '') {
      if (e.which == 46) {
        e.preventDefault();
      }
    }
    if (value === '0') {
      if (e.which != 46) {
        e.preventDefault();
      }
    }
  }
};

export const numberHandleChange = (e: any, setTarget: any) => {
  let pattern = /^\d*[.]\d{4}$/;
  let value = e.currentTarget.value;

  if (!pattern.test(value)) {
    let amountNumber = value !== '' ? value.replace(/,/g, '') : '';
    let top = amountNumber;
    let bottom = '';
    if (amountNumber.includes('.')) {
      top = amountNumber.substr(0, amountNumber.indexOf('.'));
      bottom = amountNumber.substr(amountNumber.indexOf('.'), 4);
    }
    let topResult = top.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    setTarget(topResult + bottom);
  }
};
