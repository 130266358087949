import React, { useContext, useCallback, useEffect, useState } from 'react';
import { Button, DateBox, Modal } from 'components';
import styled from 'styled-components';
import { SetUpPeriodProps } from 'constants/PropsType';
import { useHistory } from 'react-router';
import { PATH } from 'constants/APP_INFO';
import useModal from 'hooks/useModal';
import { Context } from 'contexts/Modals';
import { balanceEtherToWei } from 'helpers';
import { termOfDay, predictDateToBlockHeight } from 'helpers/time.helper';
import useBlockHeight from 'hooks/useBlockHeight';
import useCreatePool from 'hooks/useCreatePool';
import useTokenBalance from 'hooks/useTokenBalance';
import BigNumber from 'bignumber.js';
import { TxType } from 'constants/Types';
import useLastPool from '../../../hooks/useLastPool';

const SetUpPeriod: React.FC<SetUpPeriodProps> = ({
  disable,
  createPoolParams,
  fullRequired,
  addRequiredParam,
  addAdditionalParam,
}) => {
  const history = useHistory();
  const { onDismiss } = useContext(Context);
  const { blockHeight, fetchBlockHeight } = useBlockHeight();
  const { onCreatePool } = useCreatePool();
  const { fetchLastPool } = useLastPool();
  const { fetchTokenBalance } = useTokenBalance();

  const {
    required: { stakedAddress, rewardAddress, totalReward, startBlock, endBlock },
    additional: { requestedApproval, pendingTx, startDate, endDate, period, rewardTokenDecimals },
  } = createPoolParams;

  /* 시작 날짜가 현재시간과 같거나 오늘보다 전일 때 */
  const [openErrorModal1] = useModal(
    <Modal
      title="Reminders"
      error
      btnWrap={
        <Button type="green" size="big" fullWidth onClick={onDismiss}>
          Reset the Date
        </Button>
      }
    >
      Reset time expired.
      <br />
      Please reset Start Date and End Date.
    </Modal>,
  );

  /* 10일 간격이 아닐 경우 */
  const [openErrorModal2] = useModal(
    <Modal
      title="Reminders"
      error
      btnWrap={
        <Button type="green" size="big" fullWidth onClick={onDismiss}>
          Reset the Date
        </Button>
      }
    >
      End Date must be set up as 10 days after Start Date.
      <br />
      Please reset End date.
    </Modal>,
  );

  /* 잔액 비교 시 지급하고자 하는 리워드 수량이 보유 수량보다 클 때 */
  const [openErrorModal3] = useModal(
    <Modal
      title="Reminders"
      error
      btnWrap={
        <Button type="green" size="big" fullWidth onClick={onDismiss}>
          Reset reward amount
        </Button>
      }
    >
      Total Amount exceed your asset.
      <br />
      Please reset Reward Amount.
    </Modal>,
  );

  /* 모두 완료되고 최종 컨펌  */
  const [openSuccessModal] = useModal(
    <Modal
      title="Confirmation"
      guide
      btnWrap={
        <React.Fragment>
          <Button type="lineGreen" size="big" onClick={onDismiss}>
            Cancel
          </Button>
          <Button
            onClick={async () => {
              addAdditionalParam({ pendingTx: TxType.CREATEPOOL });
              onDismiss();
              const tx = await onCreatePool(
                stakedAddress,
                rewardAddress,
                balanceEtherToWei(totalReward, rewardTokenDecimals).toString(),
                startBlock,
                endBlock,
              );
              if (tx) {
                const poolAddress = await fetchLastPool();
                if (poolAddress) history.push(`${PATH.CREATE_CHECK}/${poolAddress}`);
              }
              addAdditionalParam({ pendingTx: TxType.NULL });
            }}
            type="green"
            size="big"
          >
            Create
          </Button>
        </React.Fragment>
      }
    >
      Once a staking Pool is created, you cannot modify
      <br />
      or delete the pool.
    </Modal>,
  );

  /* 날짜 관련 셋 */
  const setStartDate = useCallback(
    (date: Date) => {
      addAdditionalParam({ startDate: date });
      fetchBlockHeight();
    },
    [addAdditionalParam, fetchBlockHeight],
  );

  const setEndDate = useCallback(
    (date: Date) => {
      addAdditionalParam({ endDate: date });
      fetchBlockHeight();
    },
    [addAdditionalParam, fetchBlockHeight],
  );

  /* 선택된 날짜에 대한 예측 블록 계산 업데이트 */
  useEffect(() => {
    (async () => {
      const realHeight = await fetchBlockHeight();
      if (startDate) {
        const height = predictDateToBlockHeight(startDate, realHeight);
        addRequiredParam({ startBlock: height });
      }
      if (endDate) {
        const height = predictDateToBlockHeight(endDate, realHeight);
        addRequiredParam({ endBlock: height });
      }
      addAdditionalParam({ period: termOfDay(startDate, endDate) });
    })();
  }, [startDate, endDate, blockHeight, fetchBlockHeight, addAdditionalParam, addRequiredParam]);

  const Submit = useCallback(async () => {
    const height = await fetchBlockHeight();

    const userRealBalance = await fetchTokenBalance(rewardAddress);
    const totalRewardBalance = await balanceEtherToWei(totalReward, rewardTokenDecimals);

    if (new BigNumber(startBlock).isLessThanOrEqualTo(height)) {
      openErrorModal1();
    } else if (new BigNumber(endBlock).isLessThan(new BigNumber(startBlock).plus(57600))) {
      // TODO: 20 -> 57600
      openErrorModal2();
    } else if (new BigNumber(totalRewardBalance).isGreaterThan(userRealBalance)) {
      openErrorModal3();
    } else if (requestedApproval && fullRequired) {
      openSuccessModal();
    } else {
      return;
    }
  }, [
    endBlock,
    fetchBlockHeight,
    fetchTokenBalance,
    fullRequired,
    openErrorModal1,
    openErrorModal2,
    openErrorModal3,
    openSuccessModal,
    requestedApproval,
    rewardAddress,
    rewardTokenDecimals,
    startBlock,
    totalReward,
  ]);

  return (
    <Wrap disable={disable}>
      <Title>Set up Period</Title>
      <Table>
        <Tbody>
          <Tr>
            <Th>Start Date</Th>
            <Td>
              <DateBox date={startDate} setDate={setStartDate} blockHeight={startBlock} title="Start Date" />
              <BlockNumber>
                Prediction Block Number <span>{new BigNumber(startBlock).isNaN() ? '-' : startBlock}</span>
              </BlockNumber>
            </Td>
          </Tr>
          <Tr>
            <Th>End Date</Th>
            <Td>
              <DateBox
                date={endDate}
                setDate={setEndDate}
                startDate={startDate}
                blockHeight={endBlock}
                title="End Date"
              />
              <BlockNumber>
                Prediction Block Number <span>{new BigNumber(endBlock).isNaN() ? '-' : endBlock}</span>
              </BlockNumber>
              <Description>End Date must be set up as 10 days after the Start Date.</Description>
            </Td>
          </Tr>
        </Tbody>
      </Table>
      <Result>
        <StyleFlex>
          <Text>Staking Period</Text>
          <Amount>
            {period} <span>Days</span>
          </Amount>
        </StyleFlex>
      </Result>
      {pendingTx === TxType.CREATEPOOL ? (
        <Button size="big" fullWidth type="green" disabled>
          In Progress
        </Button>
      ) : (
        <Button size="big" fullWidth type="green" disabled={!fullRequired} onClick={Submit}>
          Create Staking Pool
        </Button>
      )}
    </Wrap>
  );
};

const Wrap = styled.div<{ disable?: boolean }>`
  background: #fff;
  border-radius: 32px;
  box-shadow: 0px 0px 10px 10px rgba(0, 0, 0, 0.01);
  width: 512px;
  height: 688px;
  margin-top: 22px;
  display: flex;
  flex-direction: column;
  padding: 49px 41px 64px;
  opacity: ${(props) => (props.disable ? 0.5 : 1)};
  position: relative;
  &:after {
    content: '';
    display: ${(props) => (props.disable ? 'block' : 'none')};
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
`;

const Title = styled.h2`
  font-size: 30px;
  font-weight: bold;
  margin-bottom: 25px;
`;

const Table = styled.table``;
const Tbody = styled.tbody``;
const Tr = styled.tr``;

const Th = styled.th`
  font-size: 18px;
  font-weight: 500;
  text-align: left;
  padding-bottom: 34px;
`;

const Td = styled.td`
  padding-bottom: 34px;
  text-align: right;
  position: relative;
`;

const BlockNumber = styled.p`
  text-align: right;
  color: #8e8e8e;
  font-size: 12px;
  position: absolute;
  bottom: 15px;
  right: 0;
  span {
    padding-left: 5px;
    text-decoration: underline;
  }
`;

const Description = styled.p`
  text-align: right;
  color: #118fc0;
  font-size: 12px;
  font-weight: 500;
  position: absolute;
  bottom: -5px;
  right: 0;
`;

const Result = styled.div`
  background: #fbfbfb;
  border-radius: 14px;
  padding: 7px 29px 68px;
  margin-top: auto;
  height: 164px;
`;

const StyleFlex = styled.div`
  display: flex;
  align-items: center;
  font-size: 18px;
  font-weight: 500;
  margin-top: 22px;
`;

const Text = styled.p`
  color: rgba(0, 0, 0, 0.8);
  flex: none;
  display: flex;
  align-items: center;
`;

const Amount = styled.p`
  margin-left: auto;
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: baseline;
  span {
    font-size: 14px;
    padding-left: 5px;
  }
`;
export default SetUpPeriod;
