import React, {Component} from 'react'
import styled from 'styled-components';
import {SelectProps} from 'constants/PropsType'
import SelectIcon from 'assets/img/select-icon.png'

type SelectState = {
    show: boolean
    class?: string
    clicked?: number
};

class Template extends Component<SelectProps, SelectState> {
    private node = React.createRef<HTMLDivElement>();
    constructor(props: SelectProps) {
        super(props);
        this.state = {
            show: false,
        };
        this.props.onChange && this.props!.onChange(this.label);
    }

    componentDidMount(): void {
        document.addEventListener('mousedown', this.handleClick, false);
    }

    componentWillUnmount(): void {
        document.removeEventListener('mousedown', this.handleClick, false);
    }

    handleClick = (e: any) => {
        if (this.node.current && !this.node.current.contains(e.target)) {
            this.setState({show: false});
        }
    };

    label = this.props.label !== undefined ? this.props.label : 'select';
    change = (i: number) => {
        this.setState({show: false, clicked: i});
        this.props.options !== undefined && (this.label = this.props.options[i]);
        this.props.onChange && this.props.onChange(this.label);
    };

    display = (e:any) => {
        e.preventDefault();
        this.setState({show: !this.state.show})
    };

    render() {
        let {show} = this.state;
        return (
            <StyleSelect ref={this.node} style={{...this.props.style}}>
                <StyleButton onClick={this.display}>{this.label}</StyleButton>
                {!show ? null :
                    <SelectList>
                        {this.props.options !== undefined && this.props.options.map((e: string, i: number) => (
                            <SelectOption
                                active={i === this.state.clicked || this.label === e ? "on" : ""}
                                key={i}
                                onClick={() => this.change(i)}>{e}</SelectOption>)
                        )}
                    </SelectList>
                }
            </StyleSelect>
        )
    }
}

const StyleSelect = styled.div`
    display: inline-block;
    position: relative;
`;

const StyleButton = styled.button`
    transition: all .2s;
    text-align:left;
    border: 1px solid #F8F8F8;
    border-radius: 10px;
    width:272px;
    height:60px;
    font-size: 16px;
    color:rgba(0,0,0,.5);
    padding: 0 23px;
    background: url(${SelectIcon}) #F8F8F8 no-repeat right 26px center;
    background-size: 21px 10.5px;
    &:focus {
        border: 1px solid #024953;
    }
`;

const SelectList = styled.ul`
    position: absolute;
    top:110%;
    width:100%;
    border-radius: 4px;
    border: 1px solid #ccc;
    box-shadow: 0 0 2px 0 rgba(176, 176, 176, 0.5);
    background:#fff;
    z-index: 1;
    overflow-y: scroll;
`;

const SelectOption = styled.li<{active:string}>`
    transition: all .2s;
    text-align: left;
    font-size: 14px;
    font-weight: 500;
    padding: 10px 32px 11px 16px;
    &:nth-child(2n) {
        background: #f7f7f7;
    }
    &:hover {
        cursor: pointer;
        background: #e9e9e9;
    }
`;

export default Template;