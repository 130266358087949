import React from 'react';
import { ThemeProvider } from 'styled-components';
import theme from '../theme';
import ModalsProvider from 'contexts/Modals';
import WalletsProvider from 'contexts/Wallets';
import { Web3ReactProvider } from '@web3-react/core';
import { getLibrary } from 'connector';
import { ApolloProvider } from '@apollo/client';
import { uniswapV2Client } from 'subgraph/client';

const Providers: React.FC = ({ children }) => {
  return (
    <ApolloProvider client={uniswapV2Client}>
      <ThemeProvider theme={theme}>
        <Web3ReactProvider getLibrary={getLibrary}>
          <WalletsProvider>
            <ModalsProvider>{children}</ModalsProvider>
          </WalletsProvider>
        </Web3ReactProvider>
      </ThemeProvider>
    </ApolloProvider>
  );
};

export default Providers;
