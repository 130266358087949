import { useCallback } from 'react';
import { useWeb3React } from '@web3-react/core';
import { createPool, getMasterContract } from 'octetfi/methods';

const useCreatePool = () => {
  const { account, chainId, library } = useWeb3React();

  const onCreatePool = useCallback(
    async (stakeAddress: string, rewardAddress: string, totalReward: string, startBlock: string, endBlock: string) => {
      try {
        const masterContract = await getMasterContract(library, chainId);
        const tx = await createPool(
          masterContract,
          stakeAddress,
          rewardAddress,
          totalReward,
          startBlock,
          endBlock,
          account,
        );
        return tx;
      } catch (e) {
        return false;
      }
    },
    [account, chainId, library],
  );
  return { onCreatePool };
};
export default useCreatePool;
