import { useCallback } from 'react';
import { useWeb3React, UnsupportedChainIdError } from '@web3-react/core';
import { connectorsByName, ConnectorNames } from '../connector';
import { switchNetwork } from '../connector/metamask';

const useAuth = () => {
  const { activate, deactivate } = useWeb3React();

  const login = useCallback(
    (connectorID: ConnectorNames) => {
      const connector = connectorsByName[connectorID];
      if (connector) {
        activate(connector, async (error: Error) => {
          if (error instanceof UnsupportedChainIdError) {
            const hasSetup = await switchNetwork();
            if (hasSetup) {
              activate(connector);
            }
          }
        });
      } else {
        console.error('Unable to find connector');
      }
    },
    [activate],
  );

  const logout = useCallback(() => {
    deactivate();
  }, [deactivate]);

  return { login, logout };
};

export default useAuth;
