import BigNumber from 'bignumber.js';

export interface StakeInfoType {
  poolAddress: string;
  stakeAddress: string;
  rewardAddress: string;
  stakeSymbol: string;
  rewardSymbol: string;
  leftBlock: BigNumber;
  totalStaked: BigNumber;
  totalReward: BigNumber;
  dailyAPR: string | null;
  earn: BigNumber;
  stakedTokenPrice: BigNumber;
  startBlock: number;
  endBlock: number;
  perBlock: string;
  unAllocReward: BigNumber;
  updatedBlock: number;
  stakeTokenDecimals: number;
  rewardTokenDecimals: number;
  addUniswapV2LiquidityUrl: string | null;
  remainingTime?: any;
}

export enum TxType {
  NULL,
  APPROVE,
  STAKE,
  UNSTAKE,
  CLAIMREWARDS,
  CREATEPOOL,
}

export interface CreatePoolType {
  required: {
    stakedAddress: string;
    rewardAddress: string;
    totalReward: BigNumber;
    startBlock: string;
    endBlock: string;
  };
  additional: {
    requestedApproval: boolean;
    pendingTx: TxType;
    stakedSymbol: string;
    rewardSymbol: string;
    rewardAmount: BigNumber;
    feeAmount: BigNumber;
    startDate: Date;
    endDate: Date;
    period: string;
    stakeTokenDecimals: number;
    rewardTokenDecimals: number;
  };
}
