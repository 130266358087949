import React from "react";
import styled, {keyframes} from 'styled-components';

interface LoadingProps {
}

const Loading: React.FC<LoadingProps> = () => {
    return (
        <StyleLoading>
            <StyleBox>
                <StyleSpan delay={.2}/>
                <StyleSpan delay={.4}/>
                <StyleSpan delay={.6}/>
                <StyleSpan delay={.8}/>
            </StyleBox>
        </StyleLoading>
    )
};

const StyleLoading = styled.div`
    width:100%;
    height:100%;
    position:fixed;
    left:0;
    top:0;
    z-index:999;
    background: rgba(0, 0, 0, 0.5);
    display:flex;
    align-items:center;
    justify-content:center;
`;

const StyleBox = styled.div`
    width:122px;
    height:122px;
    background:#fff;
    font-size:30px;
    display:flex;
    align-items:center;
    justify-content:center;
    font-weight:bold;
    border-radius: 20px;
`;

const LoadingAnimation = keyframes`
    0% {
        transform:scale(1.5);
        background:#000;
    }
    50% {
        transform:scale(1);
        background:#999;
    }
    100% {
        transform:scale(1.5);
        background:#000;
    }
`;

const StyleSpan = styled.span<{delay:number}>`
    width:5px;
    height:5px;
    background:#000;
    border-radius:50%;
    display:block;
    margin:0 3px;
    animation-timing-function: ease-in-out;
    animation-name: ${LoadingAnimation};
    animation-delay: ${props => props.delay}s;
    animation-duration: 1s;
    animation-iteration-count: infinite;
`;

export default Loading;