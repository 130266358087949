import { useCallback } from 'react';
import { approve, getTokenContract } from '../octetfi/methods';
import { useWeb3React } from '@web3-react/core';

const useApprove = () => {
  const { account, chainId, library } = useWeb3React();

  const handleApprove = useCallback(
    async (tokenAddress: string) => {
      try {
        const tokenContract = await getTokenContract(library, tokenAddress);
        const tx = await approve(tokenContract, account, chainId);
        return tx;
      } catch (e) {
        return false;
      }
    },
    [account, chainId, library],
  );

  return { onApprove: handleApprove };
};

export default useApprove;
