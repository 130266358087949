import { useCallback } from 'react';
import { getTokenContract, getBalance } from '../octetfi/methods';
import { useWeb3React } from '@web3-react/core';

const useTokenBalance = () => {
  const { account, library } = useWeb3React();

  const fetchTokenBalance = useCallback(
    async (tokenAddress: string) => {
      const tokenContract = await getTokenContract(library, tokenAddress);
      const balance = await getBalance(tokenContract, account);
      return balance;
    },
    [account, library],
  );

  return { fetchTokenBalance };
};

export default useTokenBalance;
