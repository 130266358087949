import React, { useCallback, useState, useEffect } from 'react';
import styled from 'styled-components';
import { Button } from 'components';
import { SetUpTokenProps } from 'constants/PropsType';
import SelectIcon from 'assets/img/select-icon.png';
import useModal from 'hooks/useModal';
import SelectTokenModal from './SelectTokenModal';
import useApprove from 'hooks/useApprove';
import { TxType } from 'constants/Types';
import useTokenBalance from 'hooks/useTokenBalance';
import useAllowance from 'hooks/useAllowance';
import { balanceWeiToEther, balanceViewFormat, balanceEtherToWei } from 'helpers';
import { numberFormat, numberHandleChange, numberInputKeyPress } from 'helpers/number.helper';
import BigNumber from 'bignumber.js';
import { isLessThanOrEqualTo } from 'helpers/bignumber.helper';
import { OCTET_FI_DEV_FEE_POINT } from 'octetfi/lib/constants';

const SetUpToken: React.FC<SetUpTokenProps> = ({ disable, createPoolParams, addRequiredParam, addAdditionalParam }) => {
  const {
    required: { stakedAddress, rewardAddress, totalReward },
    additional: { requestedApproval, pendingTx, stakedSymbol, rewardSymbol, feeAmount, rewardTokenDecimals },
  } = createPoolParams;
  const [viewRewardAmount, setViewRewardAmount] = useState('');

  const { fetchTokenBalance } = useTokenBalance();
  const { fetchAllowance } = useAllowance();
  const { onApprove } = useApprove();

  useEffect(() => {
    (async () => {
      const totalRewardToWei = balanceEtherToWei(totalReward, rewardTokenDecimals);
      const allowance = await fetchAllowance(rewardAddress);
      if (!!rewardAddress && totalRewardToWei.isGreaterThan(0)) {
        if (isLessThanOrEqualTo(totalRewardToWei, allowance)) {
          addAdditionalParam({ requestedApproval: true });
        } else {
          addAdditionalParam({ requestedApproval: false });
        }
      }
    })();
  }, [
    rewardAddress,
    totalReward,
    requestedApproval,
    pendingTx,
    rewardTokenDecimals,
    fetchAllowance,
    addAdditionalParam,
  ]);

  /* 리워드 수량에 따른 부가 데이터 업데이트 */
  useEffect(() => {
    const targetAmount = viewRewardAmount || '0';
    const reward = new BigNumber(targetAmount.replace(/,/g, ''));
    const total = new BigNumber(reward).multipliedBy(1000).dividedBy(new BigNumber(1000).minus(OCTET_FI_DEV_FEE_POINT));
    const fee = new BigNumber(total).multipliedBy(OCTET_FI_DEV_FEE_POINT).dividedBy(1000);

    const formatTotal = balanceEtherToWei(total, rewardTokenDecimals).toFixed(0, 1);
    const formatReward = balanceEtherToWei(reward, rewardTokenDecimals).toFixed(0, 1);
    const formatFee = balanceEtherToWei(fee, rewardTokenDecimals).toFixed(0, 1);

    const formatEtherTotal = balanceWeiToEther(new BigNumber(formatTotal), rewardTokenDecimals);
    const formatEtherReward = balanceWeiToEther(new BigNumber(formatReward), rewardTokenDecimals);
    const formatEtherFee = balanceWeiToEther(new BigNumber(formatFee), rewardTokenDecimals);

    addAdditionalParam({ feeAmount: formatEtherFee, rewardAmount: formatEtherReward });
    addRequiredParam({ totalReward: formatEtherTotal });
  }, [viewRewardAmount, rewardTokenDecimals, addAdditionalParam, addRequiredParam]);

  /* 선택된 리워드 토큰 최대 보유 수량  */
  const handleMax = useCallback(async () => {
    const balance = await fetchTokenBalance(rewardAddress);
    const max = balanceWeiToEther(balance, rewardTokenDecimals);
    let result = max.toFixed(3, 1);
    setViewRewardAmount(numberFormat(result));
  }, [fetchTokenBalance, rewardAddress, rewardTokenDecimals]);

  const handleApprove = useCallback(
    async (rewardAddress: string) => {
      try {
        if (rewardAddress) {
          const tx = await onApprove(rewardAddress);
          if (!tx) {
            addAdditionalParam({ requestedApproval: false });
          } else {
            addAdditionalParam({ requestedApproval: true });
          }
        }
      } catch (e) {
        return false;
      }
    },
    [addAdditionalParam, onApprove],
  );

  /* 스테이킹 토큰 선택 창 */
  const [openStakingTokenModal] = useModal(
    <SelectTokenModal
      title={`Select a Staking Token`}
      address={createPoolParams.required.stakedAddress}
      symbol={createPoolParams.additional.stakedSymbol}
      setAddress={(e: string) => addRequiredParam({ stakedAddress: e })}
      setSymbol={(e: string) => addAdditionalParam({ stakedSymbol: e })}
      setDecimals={(e: number) => addAdditionalParam({ stakeTokenDecimals: e })}
    />,
  );

  /* 리워드 토큰 선택 창 */
  const [openRewardTokenModal] = useModal(
    <SelectTokenModal
      title={`Select a Reward Token`}
      address={createPoolParams.required.rewardAddress}
      symbol={createPoolParams.additional.rewardSymbol}
      setAddress={(e: string) => addRequiredParam({ rewardAddress: e })}
      setSymbol={(e: string) => addAdditionalParam({ rewardSymbol: e })}
      setDecimals={(e: number) => addAdditionalParam({ rewardTokenDecimals: e })}
    />,
  );

  return (
    <Wrap disable={disable}>
      <Title>Set up Token</Title>
      <Table>
        <Tbody>
          <Tr>
            <Th>Staking Token</Th>
            <Td>
              <SelectButton onClick={openStakingTokenModal}>{stakedSymbol ? stakedSymbol : stakedAddress}</SelectButton>
            </Td>
          </Tr>
          <Tr>
            <Th>Reward Token</Th>
            <Td>
              <SelectButton onClick={openRewardTokenModal}>{rewardSymbol ? rewardSymbol : rewardAddress}</SelectButton>
            </Td>
          </Tr>
          <Tr>
            <Th>Reward Amount</Th>
            <Td>
              <InputBox>
                <MaxButton onClick={handleMax}>MAX</MaxButton>
                <Input
                  onChange={(e: any) => numberHandleChange(e, setViewRewardAmount)}
                  onKeyPress={(e: any) => numberInputKeyPress(e)}
                  type="text"
                  placeholder="0"
                  value={viewRewardAmount}
                />
                <span>{rewardSymbol}</span>
              </InputBox>
            </Td>
          </Tr>
        </Tbody>
      </Table>
      <Result>
        <StyleFlex>
          <Text>
            Fee Amount<Mark>0.{OCTET_FI_DEV_FEE_POINT}%</Mark>
          </Text>
          <Amount>
            ≒{' '}
            <p>
              {balanceViewFormat(
                balanceEtherToWei(new BigNumber(feeAmount.toFixed(3, 0)), rewardTokenDecimals),
                rewardTokenDecimals,
              )}
            </p>{' '}
            <span>{rewardSymbol}</span>
          </Amount>
        </StyleFlex>
        <StyleFlex>
          <Text>Total Amount</Text>
          <Amount>
            ≒{' '}
            <p>
              {balanceViewFormat(
                balanceEtherToWei(new BigNumber(totalReward.toFixed(3, 0)), rewardTokenDecimals),
                rewardTokenDecimals,
              )}
            </p>{' '}
            <span>{rewardSymbol}</span>
          </Amount>
        </StyleFlex>
        <SubText>
          Once you create your staking pool, 0.{OCTET_FI_DEV_FEE_POINT}% of the reward token will be deducted as fees.
        </SubText>
      </Result>
      {pendingTx === TxType.APPROVE ? (
        <Button size="big" fullWidth type="lineGreen" disabled>
          In Progress
        </Button>
      ) : !requestedApproval ? (
        <Button
          size="big"
          fullWidth
          type="lineGreen"
          disabled={!(rewardSymbol && stakedSymbol && totalReward.isGreaterThan(0))}
          onClick={async () => {
            addAdditionalParam({ pendingTx: TxType.APPROVE });
            await handleApprove(rewardAddress);
            addAdditionalParam({ pendingTx: TxType.NULL });
          }}
        >
          Approve
        </Button>
      ) : (
        <Button size="big" fullWidth type="lineGreen" disabled>
          Confirmed Approve
        </Button>
      )}
    </Wrap>
  );
};

const Wrap = styled.div<{ disable?: boolean }>`
  background: #fff;
  border-radius: 32px;
  box-shadow: 0px 0px 10px 10px rgba(0, 0, 0, 0.01);
  width: 512px;
  height: 688px;
  margin-top: 22px;
  display: flex;
  flex-direction: column;
  padding: 49px 41px 64px;
  opacity: ${(props) => (props.disable ? 0.5 : 1)};
  position: relative;
  &:after {
    content: '';
    display: ${(props) => (props.disable ? 'block' : 'none')};
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
`;

const Title = styled.h2`
  font-size: 30px;
  font-weight: bold;
  margin-bottom: 25px;
`;

const Table = styled.table``;
const Tbody = styled.tbody``;
const Tr = styled.tr``;

const Th = styled.th`
  font-size: 18px;
  font-weight: 500;
  text-align: left;
  padding-bottom: 34px;
`;

const Td = styled.td`
  padding-bottom: 34px;
  text-align: right;
  & > div {
    display: inline-flex;
    align-items: center;
    width: 272px;
    height: 60px;
    border-radius: 10px;
  }
`;

const SelectButton = styled.div`
  cursor: pointer;
  padding: 0 23px;
  color: rgba(0, 0, 0, 0.5);
  background: url(${SelectIcon}) #f8f8f8 no-repeat right 26px center;
  background-size: 21px 10.5px;
  &:focus {
    border: 1px solid #024953;
  }
`;

const InputBox = styled.div`
  background: #f8f8f8;
  padding: 0 16px;
  input {
    width: 80%;
  }
  span {
    flex: none;
    font-size: 14px;
    font-weight: 500;
    display: block;
    margin-left: 5px;
  }
`;

const MaxButton = styled.button`
  background: #efefef;
  border: 1px solid #e2e2e2;
  border-radius: 8px;
  color: #6b6b6b;
  font-size: 14px;
  font-weight: bold;
  width: 60px;
  height: 34px;
  flex: none;
`;

const Input = styled.input`
  flex: 1;
  padding-left: 20px;
  text-align: right;
  height: 100%;
  color: #000;
  font-weight: 500;
  &::placeholder {
    font-weight: normal;
    color: rgba(0, 0, 0, 0.5);
  }
`;

const Result = styled.div`
  background: #fbfbfb;
  border-radius: 14px;
  margin-top: auto;
  padding: 7px 29px 22px;
`;

const StyleFlex = styled.div`
  display: flex;
  align-items: center;
  font-size: 18px;
  font-weight: 500;
  margin-top: 22px;
`;

const Text = styled.p`
  color: rgba(0, 0, 0, 0.8);
  flex: none;
  display: flex;
  align-items: center;
`;

const Mark = styled.span`
  font-size: 12px;
  display: inline-block;
  margin-left: 6px;
  color: #118fc0;
  padding: 2px 5px;
  font-weight: bold;
  background: #dceff1;
  border-radius: 2px;
`;

const Amount = styled.div`
  margin-left: auto;
  flex: 1;
  display: flex;
  align-items: baseline;
  text-align: right;
  justify-content: flex-end;
  width: 20%;
  margin-left: 10px;
  p {
    width: 100%;
    flex: 1;
    text-overflow: ellipsis;
    white-space: nowrap;
    word-wrap: normal;
    overflow: hidden;
    padding-left: 5px;
  }
  span {
    font-size: 14px;
    font-weight: 500;
    display: inline-block;
    margin-left: 5px;
    flex: none;
  }
`;

const SubText = styled.p`
  color: #118fc0;
  font-size: 12px;
  font-weight: 500;
  line-height: 1.5;
  margin-top: 13px;
`;

export default SetUpToken;
