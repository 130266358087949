import { useCallback, useState, useEffect } from 'react';
import { getMasterContract, getPendingReward, getUser } from '../octetfi/methods';
import BigNumber from 'bignumber.js';
import { useWeb3React } from '@web3-react/core';

const useUser = (poolAddress: string) => {
  const [user, setUser] = useState({
    stakedBalance: new BigNumber(0),
    pendingReward: new BigNumber(0),
  });

  const { account, chainId, library } = useWeb3React();

  const fetchUser = useCallback(async () => {
    if (account) {
      const masterContract = await getMasterContract(library, chainId);
      const stakedBalance = await getUser(masterContract, poolAddress, account);
      const pendingReward = await getPendingReward(masterContract, poolAddress, account);
      setUser({ stakedBalance, pendingReward });
    }
  }, [account, library, chainId, poolAddress]);

  useEffect(() => {
    if (account && library) {
      fetchUser();
    }
    let refreshInterval = setInterval(fetchUser, 10000);
    return () => clearInterval(refreshInterval);
  }, [poolAddress, account, library, fetchUser]);

  return { onUpdateUser: fetchUser, user: user };
};

export default useUser;
