import React, { useCallback, useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { BackButton, Button, Modal, Page } from 'components';
import { Switch, useHistory } from 'react-router-dom';
import MetaMask from 'assets/img/metamask.png';
import CopyIcon from 'assets/img/copy.png';
import { useWeb3React } from '@web3-react/core';
import { PATH } from 'constants/APP_INFO';
import useModal from 'hooks/useModal';
import { Context } from 'contexts/Modals';
import useOwnerPools from 'hooks/useOwnerPools';
import useBlockHeight from 'hooks/useBlockHeight';
import useClaimUnAllocReward from 'hooks/useClaimUnAllocReward';
import { StakeInfoType } from 'constants/Types';
import { balanceViewFormat } from 'helpers';
import { EtherScanUrl } from '../../connector/constants';
import useAuth from 'hooks/useAuth';
import { isLessThanOrEqualTo } from 'helpers/bignumber.helper';

const Info: React.FC = () => {
  const [poolData, setPoolData] = useState([] as Array<StakeInfoType>);
  const { account, chainId } = useWeb3React();
  const history = useHistory();
  const { onDismiss } = useContext(Context);
  const { logout } = useAuth();

  const handleSignOut = useCallback(() => {
    logout();
    onDismiss();
    history.push(PATH.MAIN);
  }, [history, logout, onDismiss]);

  const { blockHeight } = useBlockHeight();

  const [openDisconnect] = useModal(
    <Modal
      title="Confirmation"
      btnWrap={
        <React.Fragment>
          <Button fullWidth type="lineGreen" size="big" onClick={onDismiss}>
            Cancel
          </Button>
          <Button fullWidth type="red" size="big" onClick={handleSignOut}>
            Disconnect
          </Button>
        </React.Fragment>
      }
    >
      You will not be able to use staking-related
      <br />
      services once you disconnect your connected
      <br />
      wallets.
    </Modal>,
  );

  const { fetchOwnerPool } = useOwnerPools();
  const { onClaimUnAllocReward } = useClaimUnAllocReward();

  useEffect(() => {
    (async () => {
      const pools = await fetchOwnerPool();
      setPoolData(
        pools.map((pool) => {
          return {
            poolAddress: pool.poolAddr,
            stakeAddress: pool.stakedToken,
            rewardAddress: pool.rewardToken,
            stakeSymbol: pool.stakeSymbol,
            rewardSymbol: pool.rewardSymbol,
            leftBlock: pool.leftBlock,
            totalStaked: pool.totalStaked,
            totalReward: pool.initReward,
            earn: pool.earn,
            dailyAPR: pool.dailyAPR,
            stakedTokenPrice: pool.stakedTokenPrice,
            startBlock: pool.startBlock,
            endBlock: pool.endBlock,
            perBlock: pool.perBlock,
            unAllocReward: pool.unAllocReward,
            updatedBlock: pool.updatedBlock,
            stakeTokenDecimals: pool.stakeTokenDecimals,
            rewardTokenDecimals: pool.rewardTokenDecimals,
            addUniswapV2LiquidityUrl: pool.addUniswapV2LiquidityUrl,
            remainingTime: pool.remainingTime,
          };
        }),
      );
    })();
  }, [blockHeight, account, fetchOwnerPool]);

  const poolInfoLink = (d: any) => {
    history.push(`${PATH.STAKE}/${d.poolAddress}`, {});
  };

  return (
    <Switch>
      <Page>
        <MaxWidth>
          <BackButton />
          <Title>Your Info</Title>
          <InfoBox>
            <InfoBoxImg />
            <InfoCont>
              <p>
                Wallet Address
                <a href={`${EtherScanUrl[chainId]}/address/${account}`} target="_black" />
                <textarea value={account} readOnly />
              </p>
              {account}
            </InfoCont>
            <InfoBoxButton onClick={openDisconnect}>Disconnect</InfoBoxButton>
          </InfoBox>

          <SubTitle>Your Staking Pool</SubTitle>
          <PoolWrap>
            <PoolHeader>
              <li>Pool</li>
              <li>Total Staked</li>
              <li>Reward token</li>
              <li>Remaining / Total Reward</li>
              <li>Remaining Day</li>
              <li />
            </PoolHeader>
            {poolData.map((d, i) => (
              <PoolBox key={i}>
                <li onClick={() => poolInfoLink(d)}>{d.stakeSymbol}</li>
                <li onClick={() => poolInfoLink(d)}>
                  {balanceViewFormat(d.totalStaked, d.stakeTokenDecimals)} {d.stakeSymbol}
                  <br />
                  <span>≒ {balanceViewFormat(d.stakedTokenPrice, d.stakeTokenDecimals)} ETH</span>
                </li>
                <li onClick={() => poolInfoLink(d)}>{d.rewardSymbol}</li>
                <li onClick={() => poolInfoLink(d)}>
                  {balanceViewFormat(d.earn, d.rewardTokenDecimals)} /{' '}
                  {balanceViewFormat(d.totalReward, d.rewardTokenDecimals)}
                </li>
                <li onClick={() => poolInfoLink(d)}>
                  {isLessThanOrEqualTo(d.leftBlock, 0) ? '-' : `${d.remainingTime.value} ${d.remainingTime.unit} left`}
                </li>
                <li>
                  {
                    // 시작 블록과 업데이트 블록이 같으면 = withdraw 활성화
                    // unAllocReward 0 이상이면 = withdraw 활성화
                    d.unAllocReward.isGreaterThan(0) || d.startBlock == d.updatedBlock ? (
                      <Button
                        disabled={d.leftBlock.isGreaterThan(0)}
                        onClick={async () => {
                          await onClaimUnAllocReward(d.poolAddress);
                        }}
                      >
                        Withdraw
                      </Button>
                    ) : (
                      <Button disabled>Claimed</Button>
                    )
                  }
                </li>
              </PoolBox>
            ))}
          </PoolWrap>
        </MaxWidth>
      </Page>
    </Switch>
  );
};

const MaxWidth = styled.div`
  width: 1024px;
  margin: 0 auto 50px;
`;

const Title = styled.h1`
  font-size: 38px;
  margin: 80px 0 42px;
`;

const InfoBox = styled.div`
  background: #fff;
  height: 132px;
  border-radius: 8px;
  border: 1px solid #ebebeb;
  display: flex;
  align-items: center;
  padding-left: 33px;
  transition: all 0.2s;
  &:hover {
    box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.1);
  }
`;

const InfoBoxImg = styled.div`
  flex: none;
  width: 68px;
  height: 68px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: 1px solid #e5e5e5;
  background: url(${MetaMask}) no-repeat center;
  background-size: auto 24px;
`;

const InfoCont = styled.div`
  margin: 0 20px;
  flex: 1;
  width: 80%;
  font-size: 18px;
  line-height: 1.7;
  display: block;
  margin-left: 15px;
  p {
    display: flex;
    align-items: center;
    font-weight: bold;
  }
  textarea {
    opacity: 0;
    position: fixed;
    top: -100px;
  }
  a {
    width: 18px;
    height: 18px;
    display: inline-block;
    cursor: pointer;
    margin-left: 10px;
    background: url(${CopyIcon}) no-repeat center;
    background-size: contain;
  }
`;

const InfoBoxButton = styled.div`
  flex: none;
  width: 186px;
  height: 100%;
  color: #d14f4f;
  font-size: 18px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-left: auto;
  cursor: pointer;
  &:before {
    content: '';
    width: 1px;
    height: 48px;
    background: #dcdcdc;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
  }
`;

const SubTitle = styled.h2`
  font-size: 20px;
  font-weight: bold;
  margin: 67px 0 24px;
`;

const PoolWrap = styled.div``;

const PoolHeader = styled.ol`
  font-size: 12px;
  color: #2e2e2e;
  padding-bottom: 9px;
  font-weight: normal;
  display: flex;
  padding-left: 33px;
  align-items: center;
  text-align: center;
  li {
    &:nth-child(1) {
      width: 15%;
    }
    &:nth-child(2) {
      width: 15%;
      text-align: left;
    }
    &:nth-child(3) {
      width: 21%;
    }
    &:nth-child(4) {
      width: 15%;
      text-align: left;
    }
    &:nth-child(5) {
      width: 20%;
    }
    &:nth-child(6) {
      width: 186px;
      flex: none;
    }
  }
`;

const PoolBox = styled.ol`
  transition: all 0.2s;
  border: 1px solid #ebebeb;
  display: flex;
  align-items: center;
  margin-top: 14px;
  font-size: 15px;
  font-weight: normal;
  background: #fff;
  padding-left: 33px;
  text-align: center;
  border-radius: 8px;
  height: 132px;
  &:hover {
    box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.1);
  }
  li {
    height: 100%;
    display: flex;
    cursor: pointer;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    &:nth-child(1) {
      width: 15%;
      font-size: 22px;
      font-weight: 900;
    }
    &:nth-child(2) {
      width: 15%;
      align-items: baseline;
      text-align: left;
    }
    &:nth-child(2) span {
      display: inline-block;
      margin-top: 5px;
      font-size: 14px;
      color: #262626;
      font-weight: 300;
    }
    &:nth-child(3) {
      width: 21%;
    }
    &:nth-child(4) {
      width: 15%;
      align-items: baseline;
      text-align: left;
    }
    &:nth-child(5) {
      width: 20%;
    }
    &:nth-child(6) {
      width: 186px;
      font-weight: 500;
      color: #2b8693;
      font-size: 18px;
      cursor: default;
      position: relative;
      flex: none;
      button {
        height: 100%;
        background: transparent;
      }
      &:before {
        content: '';
        display: block;
        width: 1px;
        height: 40px;
        background: #dcdcdc;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 0;
      }
    }
  }
`;

export default Info;
