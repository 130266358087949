import { useWeb3React } from '@web3-react/core';
import useOwnerPools from './useOwnerPools';
import { useCallback } from 'react';
import { getMasterContract, getPoolAddressByCreator } from '../octetfi/methods';

const useLastPool = () => {
  const { account, chainId, library } = useWeb3React();

  const { fetchOwnerPool } = useOwnerPools();

  const fetchLastPool = useCallback(async () => {
    const masterContract = await getMasterContract(library, chainId);
    const pools = await fetchOwnerPool();
    const poolAddr = await getPoolAddressByCreator(masterContract, account, pools.length - 1);

    return poolAddr;
  }, [account, chainId, library, fetchOwnerPool]);

  return { fetchLastPool };
};

export default useLastPool;
