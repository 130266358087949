import React, { useState, useCallback, useEffect } from 'react';
import styled from 'styled-components';
import { BackButton, Page } from 'components';
import { Switch } from 'react-router-dom';
import SetUpToken from './components/SetUpToken';
import SetUpPeriod from './components/SetUpPeriod';
import { CreatePoolType, TxType } from '../../constants/Types';
import BigNumber from 'bignumber.js';

const Create: React.FC = () => {
  const [fullRequired, setFullRequired] = useState(false);
  const [createPoolParams, setCreatePoolParams] = useState<CreatePoolType>({
    required: {
      stakedAddress: null,
      rewardAddress: null,
      totalReward: new BigNumber(0),
      startBlock: null,
      endBlock: null,
    },
    additional: {
      requestedApproval: false,
      pendingTx: TxType.NULL,
      stakedSymbol: null,
      rewardSymbol: null,
      rewardAmount: new BigNumber(0),
      feeAmount: new BigNumber(0),
      startDate: new Date(),
      endDate: new Date(),
      period: null,
      stakeTokenDecimals: 0,
      rewardTokenDecimals: 0,
    },
  });
  const addRequiredParam = useCallback((e: Object) => {
    setCreatePoolParams((prev) => ({
      required: {
        ...prev.required,
        ...e,
      },
      additional: {
        ...prev.additional,
      },
    }));
  }, []);
  const addAdditionalParam = useCallback((e: Object) => {
    setCreatePoolParams((prev) => ({
      required: {
        ...prev.required,
      },
      additional: {
        ...prev.additional,
        ...e,
      },
    }));
  }, []);

  /* 초기 진입 시 단 한번 렌딩 - 시작 날짜 = 현재 시간 + 10분 / 끝 날짜 = 시작 날짜 + 10일 */
  useEffect(() => {
    const today = new Date();
    addAdditionalParam({ startDate: new Date(today.setMinutes(today.getMinutes() + 10)) });
    addAdditionalParam({ endDate: new Date(today.setDate(today.getDate() + 10)) });
  }, [addAdditionalParam]);

  /* 풀 생성 버튼 활성화에 필요한 값이 풀필 상태인지 확인 
        1. approve가 되어있는가?
        2. 스테이킹 토큰 주소가 입력되었는가?
        3. 리워드 토큰 주소가 입력되었는가?
        4. 시작 블록이 입력되었는가?
        5. 끝 블록이 입력되었느가?
        6. 총 리워드 수가 0.001 보다 큰 가?
        7. 총 리워드 수가 보유 수량 보다 작은가? 
    */
  useEffect(() => {
    (async () => {
      const {
        required: { stakedAddress, rewardAddress, totalReward, startBlock, endBlock },
        additional: { requestedApproval },
      } = createPoolParams;
      // const balance = await fetchTokenBalance(rewardAddress)
      setFullRequired(
        requestedApproval &&
          !!stakedAddress &&
          !!rewardAddress &&
          !!startBlock &&
          !!endBlock &&
          totalReward.isGreaterThan(0.001),
        // && totalReward.isLessThanOrEqualTo(balanceWeiToEther(balance))
      );
    })();
  }, [createPoolParams.required, createPoolParams.additional.requestedApproval, createPoolParams]);
  return (
    <Switch>
      <Page>
        <MaxWidth>
          <BackButton />
          <StyleWrap>
            <SetUpToken
              createPoolParams={createPoolParams}
              addRequiredParam={addRequiredParam}
              addAdditionalParam={addAdditionalParam}
            />
            <SetUpPeriod
              createPoolParams={createPoolParams}
              addRequiredParam={addRequiredParam}
              addAdditionalParam={addAdditionalParam}
              fullRequired={fullRequired}
            />
          </StyleWrap>
        </MaxWidth>
      </Page>
    </Switch>
  );
};

const MaxWidth = styled.div`
  width: 1024px;
  margin: 0 auto 50px;
`;

const StyleWrap = styled.div`
  display: flex;
  align-items: center;
`;

export default Create;
