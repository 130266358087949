import React, { useCallback, useState, useEffect } from 'react';
import styled from 'styled-components';
import { Button } from 'components/Button';
import { Modal } from 'components/Modal';
import SearchIcon from 'assets/img/search-icon.png';
import { ModalProps } from 'constants/PropsType';
import useTokenSymbol from 'hooks/useTokenSymbol';
import useIsCA from 'hooks/useIsCA';
import { SelectTokenProps } from 'constants/PropsType';
import { getDecimals, getTokenContract } from '../../../octetfi/methods';
import { useWeb3React } from '@web3-react/core';

const SelectTokenModal: React.FC<SelectTokenProps> = ({
  onDismiss,
  title,
  address,
  symbol,
  setAddress,
  setSymbol,
  setDecimals,
}) => {
  const [ableToAdd, setAbleToAdd] = useState(false);
  const [decimal, setDecimal] = useState(18);
  const [viewAddress, setViewAddress] = useState(null);
  const [viewSymbol, setViewSymbol] = useState(null);
  const { library: provider } = useWeb3React();
  const { onTokenSymbol } = useTokenSymbol();
  const { onIsCA } = useIsCA();

  const addToken = useCallback(async () => {
    const isCA = await onIsCA(viewAddress);
    if (isCA) {
      setAddress(viewAddress);
      setSymbol(viewSymbol);
      /*
            const tokenContract = await getTokenContract(provider, viewAddress);
            const decimals = await getDecimals(tokenContract);
            */
      setDecimals(decimal);
    } else setAddress(null);
    onDismiss();
  }, [onIsCA, viewAddress, setAddress, onDismiss, setSymbol, viewSymbol, setDecimals, decimal]);
  useEffect(() => {
    (async function () {
      const symbol = await onTokenSymbol(viewAddress);
      if (symbol) {
        setViewSymbol(symbol);
        //setSymbol(symbol)
        const tokenContract = await getTokenContract(provider, viewAddress);
        const decimals = await getDecimals(tokenContract);
        if (decimals <= 18 && decimals >= 4) {
          setDecimal(decimals); //
          setAbleToAdd(true);
        } else {
          setDecimal(0);
          setAbleToAdd(false);
        }
      } else {
        setAbleToAdd(false);
        setViewSymbol(null);
        /*
                setAddress(null)
                setSymbol(null)
                */
      }
    })();
  }, [viewAddress, onIsCA, onTokenSymbol, setViewSymbol, provider]);

  return (
    <Modal title={title}>
      <Input
        onChange={(e: any) => {
          setViewAddress(e.currentTarget.value);
        }}
        placeholder="Please enter the contract address of token"
      />
      <Text>Token Name</Text>
      <SearchResult>{viewSymbol}</SearchResult>
      <Button onClick={addToken} type="green" size="big" fullWidth disabled={!ableToAdd}>
        Add token
      </Button>
    </Modal>
  );
};

const Input = styled.input`
  background: url(${SearchIcon}) #f8f8f8 no-repeat left 30px center;
  background-size: 15px;
  color: rgba(0, 0, 0, 0.6);
  font-size: 16px;
  width: 100%;
  height: 60px;
  border-radius: 10px;
  padding: 0 33px 0 60px;
  text-align: center;
`;

const Text = styled.p`
  font-size: 18px;
  margin-top: 30px;
  font-weight: 500;
  padding-bottom: 19px;
  border-bottom: 1px solid #e2e2e2;
`;

const SearchResult = styled.div`
  height: 200px;
  width: 430px;
  padding: 28px 0;
  font-size: 22px;
  font-weight: bold;
`;

export default SelectTokenModal;
