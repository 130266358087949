import React, { useState, useEffect, createContext } from 'react';
import { useWeb3React } from '@web3-react/core';
import useKeepConnect from 'hooks/useKeepConnect';
import useInactiveListener from 'hooks/useInactiveListener';

interface WalletsContext {
  activatingConnector: any;
  setActivatingConnector: (e: any) => void;
}

export const WalletsContext = createContext<WalletsContext>({
  activatingConnector: undefined,
  setActivatingConnector: undefined,
});

const Wallets: React.FC = ({ children }) => {
  const { connector } = useWeb3React();

  const [activatingConnector, setActivatingConnector] = useState<any>();

  useEffect(() => {
    if (activatingConnector && activatingConnector === connector) {
      setActivatingConnector(undefined);
    }
  }, [activatingConnector, connector]);

  const triedEager = useKeepConnect();

  useInactiveListener(!triedEager || !!activatingConnector);

  return (
    <WalletsContext.Provider
      value={{
        activatingConnector,
        setActivatingConnector,
      }}
    >
      {children}
    </WalletsContext.Provider>
  );
};

export default Wallets;
