import React from "react";
import styled from 'styled-components';
import logo from "assets/img/logo.png";
import bg from 'assets/img/mb-mainBg.png';
import img from 'assets/img/mb-mainImg.png';
import medium from 'assets/img/medium.png';
import BannerLogoImg from "../../assets/img/banner_logo.png";

const WrapMobile: React.FC = () => {
    return (
        <StyledWrap>
            <StyleBanner rel="noopener noreferrer" href="https://hexlant.com" target="_blank">
                <img style={{height: 36, marginRight: 14}} src={BannerLogoImg} alt='octet'/>
                Would you like to make a blockchain service? Start with an Octet Wallet
            </StyleBanner>
            <StyleHeader>
                <img height={26} style={{cursor: 'pointer'}} src={logo} alt="octet"/>
            </StyleHeader>
            <StyleBody>
                <StyleTitle>A better DeFi<br/>Experience new staking</StyleTitle>
                <StyleText>Octetfi is only available for Web browser.</StyleText>
                <img width="90%" src={img} alt="octetfi"/>
                <StyleFooter>
                    <p>© Hexlant Inc.</p>
                    <a href="https://medium.com/octetfi" target="_blank"><img height={10} src={medium} alt="medium"/></a>
                </StyleFooter>
            </StyleBody>
        </StyledWrap>
    );
};
const StyledWrap = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  background: #fff;
  width: 100vw;
  overflow-y: scroll;
  height:100vh;
`;

const StyleBanner = styled.a`
  width: 100%;
  background: #024953;
  color: #fff;
  font-size: 12px;
  line-height: 1.5;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all .2s;
  flex: none;
  padding: 10px 5%;

  img {
    flex: none;
  }
`;

const StyleHeader = styled.div`
  width: 100%;
  height: 65px;
  flex: none;
  border-bottom: 1px solid rgba(3, 133, 162, 0.2);
  display: flex;
  align-items: center;
  padding: 0 5%;
`;

const StyleBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  height:80%;
  width: 100%;
  background: url(" ${bg} ") no-repeat top right;
  background-size: 100% auto;
`;

const StyleTitle = styled.h1`
    font-size:30px;
    line-height:36px;
    letter-spacing: -0.02em;
    margin: 60px 0 15px;
    text-align:center;
`;

const StyleText = styled.p`
    font-size:15px;
    font-weight:500;
    margin-bottom: 60px;
`;

const StyleFooter = styled.div`
    border-top: 1px solid rgba(3, 133, 162, 0.1);
    height:46px;
    display:flex;
    align-items:center;
    padding: 0 5%;
    margin-top:auto;
    width:100%;
    color: rgba(0,0,0,.2);
    font-weight:700;
    letter-spacing: -0.02em;
    font-size:12px;
    flex:none;
    a {
        margin-left:auto;
    }
`;

export default WrapMobile;
