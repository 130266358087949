import { useCallback } from 'react';
import { claimReward, getMasterContract } from '../octetfi/methods';
import { useWeb3React } from '@web3-react/core';

const useClaimReward = (poolAddress: string) => {
  const { account, chainId, library } = useWeb3React();

  const handleClaimReward = useCallback(async () => {
    try {
      const masterContract = await getMasterContract(library, chainId);
      const tx = await claimReward(masterContract, poolAddress, account);
      return tx;
    } catch (e) {
      return false;
    }
  }, [account, chainId, library, poolAddress]);

  return { onClaimReward: handleClaimReward };
};

export default useClaimReward;
