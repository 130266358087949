import { useState, useCallback, useEffect } from 'react';
import { useWeb3React } from '@web3-react/core';
import { getBlockHeight } from 'octetfi/methods';

const useBlockHeight = () => {
  const [blockHeight, setBlockHeight] = useState(0);
  const { library } = useWeb3React();

  const fetchBlockHeight = useCallback(async () => {
    if (library) {
      const latestHeight = await getBlockHeight(library);
      if (blockHeight !== latestHeight) {
        setBlockHeight(latestHeight);
      }
      return latestHeight;
    }
  }, [blockHeight, library]);

  useEffect(() => {
    if (library) {
      let refreshInterval = setInterval(fetchBlockHeight, 5000);
      return () => clearInterval(refreshInterval);
    }
  }, [fetchBlockHeight, library]);

  return { blockHeight, fetchBlockHeight };
};
export default useBlockHeight;
