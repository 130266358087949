import BigNumber from 'bignumber.js';
import { ethers } from 'ethers';

/**
 * wei	0
 * kwei	3
 * mwei	6
 * gwei	9
 * szabo	12
 * finney	15
 * ether	18
 */

interface AvailableAmountType {
  value: string | number | BigNumber;
}

const toUnitString: { [key: number]: string } = {
  0: 'wei',
  3: 'kwei',
  6: 'mwei',
  9: 'gwei',
  12: 'szabo',
  15: 'finney',
  18: 'ether',
};

export const toCommify = (value: AvailableAmountType) => {
  return ethers.utils.commify(value.toString());
};

export const formatUnits = (value: AvailableAmountType, unit: string = 'ether') => {
  return ethers.utils.formatUnits(value.toString(), unit);
};

export const parseUnits = (value: AvailableAmountType, unit: string = 'ether') => {
  return ethers.utils.parseUnits(value.toString(), unit);
};

export const formatUnitsUsingDecimals = (value: AvailableAmountType, unit: number = 18) => {
  return formatUnits(value, toUnitString[unit]);
};

export const parseUnitsUsingDecimals = (value: AvailableAmountType, unit: number = 18) => {
  return parseUnits(value, toUnitString[unit]);
};
