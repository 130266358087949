import React from 'react';
import styled from 'styled-components';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { isIE, BrowserView, MobileView } from 'react-device-detect';
import { Header, Wrap, WrapMobile } from 'components';
import { Home, Stake, Create, CreateCheck, Info } from 'views';

const Routes: React.FC = () => {
  return (
    <>
      {isIE ? (
        <StyleBrowserNotSupport>
          현재 사용 중인 브라우저는 지원하지 않는 브라우저입니다.
          <br />
          최적의 환경을 위해{' '}
          <a
            href="https://www.google.co.kr/chrome/?brand=CHBD&gclid=CjwKCAjw_Y_8BRBiEiwA5MCBJtVpEh36UJOHf8vDg9Q5FoouGMxlXm6HClqKFwTXYEB9xCH2aDr0sxoCxdQQAvD_BwE&gclsrc=aw.ds"
            target="_blank"
          >
            다른 브라우저
          </a>
          를 이용해주세요.
        </StyleBrowserNotSupport>
      ) : (
        <Router>
          <BrowserView>
            <Wrap>
              <Header />
              <Switch>
                <Route path="/" exact>
                  <Home />
                </Route>
                <Route path="/info">
                  <Info />
                </Route>
                <Route path="/stake">
                  <Stake />
                </Route>
                <Route path="/create">
                  <Create />
                </Route>
                <Route path="/create_check">
                  <CreateCheck />
                </Route>
              </Switch>
            </Wrap>
          </BrowserView>
          <MobileView>
            <WrapMobile />
          </MobileView>
        </Router>
      )}
    </>
  );
};

const StyleBrowserNotSupport = styled.div`
  background: #f6f6f6;
  text-align: center;
  border: 1px solid #ccc;
  padding: 20px;
  font-size: 14px;
  line-height: 1.5;
  a {
    color: dodgerblue;
    font-weight: bold;
  }
`;

export default Routes;
