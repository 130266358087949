import React, {useCallback, useState} from 'react';
import styled from 'styled-components';
import {Page, Tab} from 'components';
import Card from './components/Card';
import {Button} from 'components';
import {useWeb3React} from '@web3-react/core';
import useModal from 'hooks/useModal';
import WalletProviderModal from 'components/Header/components/WalletProviderModal';

const Home: React.FC = () => {
  const { account } = useWeb3React();
  const [tab, setTab] = useState(true);
  const [onPresentWalletProviderModal] = useModal(<WalletProviderModal />, 'provider');
  const handleConnectWallet = useCallback(() => {
    onPresentWalletProviderModal();
  }, [onPresentWalletProviderModal]);

  return (
    <Page>
      <StyleH1>
        A better DeFi
        <br />
        Experience new staking
      </StyleH1>
      <Tab
        tabCont={[
          {
            text: 'Live',
            event: () => setTab(true)
          },
            {
                text: 'Finished',
                event: () => setTab(false)
            },
        ]}
      />
      {account ? (
          <StyleWrap>
              <StyleTable>
                  <colgroup>
                      <col width="20%"/>
                      <col width="22%"/>
                      <col width="13%"/>
                      <col width="13%"/>
                      <col width="16%"/>
                      <col width="16%"/>
                  </colgroup>
                  <tbody>
                  <tr>
                      <th>Name</th>
                      <th>Total Staked</th>
                      <th>Reward token</th>
                      <th>APR</th>
                      <th>Remaining</th>
                      <th>Total Reward</th>
                  </tr>
                  <Card tabIndex={tab}/>
                  </tbody>
              </StyleTable>
          </StyleWrap>
      ) : (
        <StyleEmptyCard>
          <StyleCard />
          <StyleCard />
          <StyleCard />
          <StyleCardText>
            <p>Ready to explore Octetfi?</p>
            <Button type="green" onClick={handleConnectWallet}>
              Connect to a Wallet
            </Button>
          </StyleCardText>
        </StyleEmptyCard>
      )}
    </Page>
  );
};

const StyleWrap = styled.div`
  //background:#fff;
  //padding:20px 20px 0;
  margin-bottom:150px;
`;

const StyleTable = styled.table`
    th {
      height:50px;
      border-bottom:1px solid #eaeaea;
      &:nth-child(1) {text-align:left;padding-left:20px}
      &:nth-child(2) {text-align:left}
      &:nth-child(3) {text-align:center}
      &:nth-child(4) {text-align:center}
      &:nth-child(5) {text-align:right}
      &:nth-child(6) {text-align:right;padding-right:20px}
    }
`;

const StyleH1 = styled.h1`
  font-size: 52px;
  line-height: 1.2;
  letter-spacing: -0.02em;
  margin-bottom: 100px;
`;

const StyleEmptyCard = styled.div`
  display: flex;
  align-items: center;
  margin: 0 -9.5px 50px;
  position: relative;
  img {
    position: absolute;
    top: -100px;
    left: 50%;
    transform: translateX(-50%);
    height: 195px;
  }
`;

const StyleCard = styled.div`
  width: calc(33.333% - 19px);
  height: 500px;
  background: linear-gradient(180deg, #ffffff 0%, rgba(255, 255, 255, 0) 100%);
  margin: 0 9.5px 19px;
  position: relative;
`;

const StyleCardText = styled.div`
  font-size: 26px;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  display: inline-block;
  transform: translate(-50%, -50%);
  font-weight: bold;
  button {
    margin-top: 22px;
    height: 50px;
    padding: 0 37px;
  }
`;

export default Home;
