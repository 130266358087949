import React, { useState, useCallback } from 'react';
import styled from 'styled-components';
import { Button, Value, Tab } from 'components';
import StakingIcon01 from 'assets/img/staking-icon01.png';
import StakingIcon02 from 'assets/img/staking-icon03.png';
import { PoolInfoProps } from '../../../constants/PropsType';
import { balanceViewFormat, balanceWeiToEther } from 'helpers';
import { numberFormat } from 'helpers/number.helper';
import useClaimReward from '../../../hooks/useClaimReward';
import { TxType } from '../../../constants/Types';
import BigNumber from 'bignumber.js';
import UnstakeTokenModal from './UnstakeTokenModal';
import useModal from '../../../hooks/useModal';
import { registerToken } from 'connector/metamask';
import useTokenSymbol from 'hooks/useTokenSymbol';
import { isLessThanOrEqualTo } from 'helpers/bignumber.helper';

const PoolInfo: React.FC<PoolInfoProps> = ({
  handlePending,
  pendingTx,
  poolAddress,
  stakeAddress,
  rewardAddress,
  stakeSymbol,
  rewardSymbol,
  user,
  startBlock,
  endBlock,
  perBlock,
  leftBlock,
  earn,
  totalStaked,
  totalReward,
  stakeTokenDecimals,
  rewardTokenDecimals,
  stakedTokenUniswapInfoUrl,
  rewardTokenUniswapInfoUrl,
  remainingTime,
}) => {
  const [tabIndex, setTabIndex] = useState(0);
  const { onClaimReward } = useClaimReward(poolAddress);
  const { onTokenSymbol } = useTokenSymbol();

  const [openUnstakeModal] = useModal(
    <UnstakeTokenModal
      stakedBalance={user.stakedBalance}
      stakeSymbol={stakeSymbol}
      stakeTokenDecimals={stakeTokenDecimals}
      handlePending={handlePending}
      pendingTx={pendingTx}
      poolAddress={poolAddress}
    />,
  );

  const handleClaimReward = useCallback(async () => {
    try {
      if (user.pendingReward.isGreaterThan(0)) {
        await onClaimReward();
      }
    } catch (e) {
      return false;
    }
  }, [user, onClaimReward]);

  const handleTokenRegister = useCallback(
    async (address, decimals) => {
      const tokenSymbol = await onTokenSymbol(address, true);
      await registerToken(address, tokenSymbol, decimals);
    },
    [onTokenSymbol],
  );

  return (
    <StyleLeft>
      <Tab
        style={{ marginBottom: 45 }}
        tabCont={[
          {
            text: 'Your Pool',
            event: () => {
              setTabIndex(0);
            },
          },
          {
            text: 'Pool Info',
            event: () => {
              setTabIndex(1);
            },
          },
        ]}
      />
      {tabIndex === 0 ? (
        <StylePool>
          <StylePoolMy>
            <img src={StakingIcon01} alt="staked tokens" />
            <div>
              Your Staked Tokens
              <StyleAddTokenButton>
                <button
                  onClick={async () => {
                    await handleTokenRegister(stakeAddress, stakeTokenDecimals);
                  }}
                >
                  + Add to Wallet
                </button>
              </StyleAddTokenButton>
              <p>
                {numberFormat(balanceWeiToEther(user.stakedBalance, stakeTokenDecimals).toFixed(3, 1))}{' '}
                <b>{stakeSymbol}</b>
              </p>
            </div>
          </StylePoolMy>
          <StylePoolMy>
            <img src={StakingIcon02} alt="staked tokens" />
            <div>
              Your Reward Tokens
              <StyleAddTokenButton>
                <button
                  onClick={async () => {
                    await handleTokenRegister(rewardAddress, rewardTokenDecimals);
                  }}
                >
                  + Add to Wallet
                </button>
              </StyleAddTokenButton>
              <p>
                <Value decimals={3} value={balanceViewFormat(user.pendingReward, rewardTokenDecimals)} />{' '}
                <b>{rewardSymbol}</b>
              </p>
            </div>
          </StylePoolMy>
          <StyleLine />
          {
            <StyleButtonWrap>
              <Button
                type="whiteGreen"
                size="big"
                disabled={user.stakedBalance.isLessThanOrEqualTo(0) || pendingTx !== TxType.NULL}
                style={{ marginRight: 10 }}
                onClick={openUnstakeModal}
              >
                {pendingTx == TxType.UNSTAKE ? (
                  'In Progress'
                ) : (
                  <span>
                    Unstake &<br />
                    Claim Rewards
                  </span>
                )}
              </Button>
              <Button
                type="whiteGreen"
                size="big"
                disabled={user.pendingReward.isLessThanOrEqualTo(0) || pendingTx !== TxType.NULL}
                onClick={async () => {
                  await handlePending(TxType.CLAIMREWARDS);
                  await handleClaimReward();
                  await handlePending(TxType.NULL);
                }}
              >
                {pendingTx == TxType.CLAIMREWARDS ? 'In Progress' : 'Claim Rewards'}
              </Button>
            </StyleButtonWrap>
          }
        </StylePool>
      ) : (
        <StylePool>
          <StylePoolInfo>
            Farming period
            <p>
              {startBlock}
              <br />~ {endBlock}
            </p>
          </StylePoolInfo>
          <StylePoolInfo>
            Remaining period
            <p>{isLessThanOrEqualTo(leftBlock, 0) ? '-' : `${remainingTime.value} ${remainingTime.unit} left`}</p>
          </StylePoolInfo>
          <StyleLine />
          <StylePoolInfo>
            Staking Token
            <p>
              <a href={stakedTokenUniswapInfoUrl}>{stakeSymbol}</a>
            </p>
          </StylePoolInfo>
          <StylePoolInfo>
            Total Staked
            <p>{balanceViewFormat(totalStaked, stakeTokenDecimals).toString()}</p>
          </StylePoolInfo>
          <StyleLine />
          <StylePoolInfo>
            Reward Token
            <p>
              <a href={rewardTokenUniswapInfoUrl}>{rewardSymbol}</a>
            </p>
          </StylePoolInfo>
          <StylePoolInfo>
            Total Reward
            <p>{balanceViewFormat(new BigNumber(totalReward), rewardTokenDecimals).toString()}</p>
          </StylePoolInfo>
          <StylePoolInfo>
            Remaining Reward
            <p>{balanceViewFormat(earn, rewardTokenDecimals).toString()}</p>
          </StylePoolInfo>
          <StylePoolInfo>
            Reward per Block
            <p>{balanceViewFormat(new BigNumber(perBlock), rewardTokenDecimals).toString()}</p>
          </StylePoolInfo>
          <StyleLine />
          <StylePoolInfoLink
            href="https://medium.com/octetfi/octetfi-a-better-defi-4770899e6dae?source=collection_home---4------0-----------------------"
            target="_black"
          >
            Are you curious about the Octetfi?
          </StylePoolInfoLink>
        </StylePool>
      )}
    </StyleLeft>
  );
};

const StyleLeft = styled.div`
  width: 400px;
  height: 688px;
  padding: 57px 0 54px;
  margin-left: 95px;
  display: flex;
  flex-direction: column;
`;

const StylePool = styled.div`
  flex: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const StylePoolMy = styled.div`
  display: flex;
  margin-bottom: 47px;
  font-size: 18px;
  img {
    flex: none;
    height: 25px;
    margin-right: 10px;
  }
  p {
    display: flex;
    align-items: baseline;
    margin-top: 11px;
    font-size: 24px;
    font-weight: 500;
    b {
      font-weight: 500;
      padding-left: 5px;
      font-size: 18px;
    }
  }
`;

const StyleButtonWrap = styled.div`
  margin-top: auto;
  display: flex;
  align-items: center;
  button {
    flex: 1;
    width: 50%;
  }
`;

const StylePoolInfo = styled.div`
  display: flex;
  align-items: flex-start;
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 20px;
  p {
    font-weight: 400;
    margin-left: auto;
    text-align: right;
    a {
      color: #5c7fd8;
    }
  }
`;

const StylePoolInfoLink = styled.a`
  font-size: 18px;
  font-weight: 900;
  margin-top: auto;
  color: #118fc0;
  cursor: pointer;
  display: block;
`;

const StyleLine = styled.div`
  width: 100%;
  height: 1px;
  background: #e9e9e9;
  margin: 5px 0 25px;
`;

const StyleAddTokenButton = styled.div`
  display: inline;

  margin-left: 10px;
  width: 100px;
  height: 20px;

  background: #daebee;
  border-radius: 6px;

  button {
    width: 100px;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 25px;
    /* identical to box height */

    color: #457279;
  }
`;

export default PoolInfo;
