import { useCallback } from 'react';
import { useWeb3React } from '@web3-react/core';
import { getMasterContract, getPool, getPoolAddressByCreator } from 'octetfi/methods';

const useOwnerPools = () => {
  const { account, chainId, library } = useWeb3React();

  const fetchOwnerPool = useCallback(async () => {
    const masterContract = await getMasterContract(library, chainId);
    let count = 0;
    const poolList = [];
    while (true) {
      try {
        const poolAddress = await getPoolAddressByCreator(masterContract, account, count);
        poolList.push(poolAddress);
        count += 1;
      } catch (e) {
        break;
      }
    }

    const poolInfos = await Promise.all(
      poolList.map(async (poolAddr) => {
        return await getPool(library, masterContract, poolAddr);
      }),
    );

    return poolInfos;
  }, [account, chainId, library]);

  return { fetchOwnerPool };
};

export default useOwnerPools;
