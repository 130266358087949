import React, { useState } from 'react';
import styled from 'styled-components';
import dateIcon from 'assets/img/date-icon.png';
import DatePicker from 'react-datepicker';
import './react-datepicker.css';
import { dateFormat } from 'helpers/time.helper';
import { DateProps } from 'constants/PropsType';

interface Props extends DateProps {}

const DateBox: React.FC<Props> = ({ date, setDate, title, startDate, blockHeight }) => {
  const [open, setOpen] = useState(false);
  const handleChange = (date: Date) => {
    setDate(date);
  };
  const DatePortal = ({ children }: any) => {
    return (
      <StyleDatePortal>
        <StyleDateCont>
          <StyleTop>
            <h2>{title}</h2>
            <div>
              {dateFormat(date)}
              <br />
              <p>
                Prediction Block Number <span>{blockHeight}</span>
              </p>
            </div>
            <button
              onClick={() => {
                setOpen(false);
              }}
            >
              Confirm
            </button>
          </StyleTop>
          <StyleBottom>{children}</StyleBottom>
        </StyleDateCont>
      </StyleDatePortal>
    );
  };

  return (
    <React.Fragment>
      <DateWrap>
        <Icon onClick={() => setOpen(true)}>
          <img src={dateIcon} alt="date" />
        </Icon>
        {open && (
          <DatePicker
            minDate={new Date()}
            inline
            calendarContainer={DatePortal}
            selected={date}
            onChange={handleChange}
            dateFormat="dd / MM / yyyy h:mm aa"
            fixedHeight
            withPortal
            showTimeInput
            timeInputLabel="Time"
            shouldCloseOnSelect={false}
            highlightDates={startDate && [startDate]}
            onClickOutside={() => {
              setOpen(false);
            }}
          />
        )}
        <DateInput>{dateFormat(date)}</DateInput>
      </DateWrap>
    </React.Fragment>
  );
};

const DateWrap = styled.div`
  background: #f8f8f8;
  border-radius: 10px;
  width: 272px;
  display: inline-flex;
  height: 60px;
  align-items: center;
  padding: 0 26px 0 16px;
  & > div:nth-child(2) {
    flex: 1;
    width: 100%;
    height: 100%;
  }
`;

const Icon = styled.span`
  width: 28px;
  height: 28px;
  flex: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: rgba(196, 196, 196, 0.4);
  border-radius: 6px;
  cursor: pointer;
  img {
    height: 16px;
  }
`;

const DateInput = styled.div`
  text-align: right;
  margin-left: 23px;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const StyleDatePortal = styled.div``;

const StyleDateCont = styled.div`
  background: #fff;
  width: 667px;
  padding: 38px 46px 38px;
  border-radius: 8px;
`;

const StyleTop = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 34px;
  h2 {
    font-size: 30px;
    font-weight: bold;
    margin-bottom: ;
  }
  div {
    margin: 0 47px 0 auto;
    font-size: 16px;
    font-weight: 500;
    p {
      font-size: 12px;
      color: #8e8e8e;
      font-weight: normal;
      margin-top: 4px;
      span {
        text-decoration: underline;
        margin-left: 5px;
        display: inline-block;
      }
    }
  }
  button {
    background: #e9f5f6;
    color: #024953;
    font-size: 18px;
    font-weight: 900;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 156px;
    height: 70px;
    border-radius: 14px;
    transition: all 0.2s;
    &:hover {
      background: #dee9ea;
    }
  }
`;

const StyleBottom = styled.div`
  display: flex;
  position: relative;
`;

export default DateBox;
