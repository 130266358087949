import React from "react";
import styled from 'styled-components';
import {Footer} from 'components'

const Page: React.FC = ({children}) => (
    <StyleWrap>
        <StyleCont>
            {children}
        </StyleCont>
        <Footer/>
    </StyleWrap>
);

const StyleWrap = styled.div`
    width:100%;
    height:100%;
    display:flex;
    flex-direction: column;
    overflow-y: scroll;
`;

const StyleCont = styled.div`
    width: 1160px;
    padding-top: 100px;
    margin: 0 auto;
`;

export default Page;