import { useCallback } from 'react';
import { useWeb3React } from '@web3-react/core';
import { getTokenContract, getTokenSymbol } from '../octetfi/methods';
import { getUniswapV2Pair } from '../subgraph';
import { isNull } from '../helpers/type.helper';

const useTokenSymbol = () => {
  const { library } = useWeb3React();

  const handleTokenSymbol = useCallback(
    async (tokenAddress: string, noPair: boolean = false) => {
      try {
        const isPair = await getUniswapV2Pair({ pairAddress: tokenAddress });

        // Pair 토큰일 아닐 경우, 단일 심볼명 가져오기 || noPair true일경우,
        if (isNull(isPair) || noPair) {
          const tokenContract = await getTokenContract(library, tokenAddress);
          const symbol = await getTokenSymbol(tokenContract);
          return symbol;
        }

        // Pair 토큰일 경우, 각 각 심볼명 가져오기
        const pairSymbol = `${isPair.token0.symbol}/${isPair.token1.symbol} UNI-V2 LP`;
        return pairSymbol;
      } catch (e) {
        return null;
      }
    },
    [library],
  );

  return { onTokenSymbol: handleTokenSymbol };
};

export default useTokenSymbol;
