import React, { useEffect, useState, useContext } from 'react';
import styled from 'styled-components';
import { ModalProps } from 'constants/PropsType';
import { Modal } from 'components/Modal';
import { useWeb3React } from '@web3-react/core';
import { ConnectorNames } from 'connector';
import MetaMask from 'assets/img/metamask.png';
import useAuth from 'hooks/useAuth';

const WalletProviderModal: React.FC<ModalProps> = ({ onDismiss }) => {
  const { account } = useWeb3React();
  const { login } = useAuth();

  useEffect(() => {
    if (account) {
      onDismiss();
    }
  }, [account, onDismiss]);

  return (
    <Modal title="Connect to a wallet" close>
      <SelectWallet>
        <Img>
          <img src={MetaMask} alt="metaMask" />
        </Img>
        Metamask
        <SelectWalletButton
          onClick={() => {
            login(ConnectorNames.Injected);
          }}
        >
          <i className="xi-plus" />
          Connect
        </SelectWalletButton>
      </SelectWallet>
      <div style={{ height: 20 }} />
    </Modal>
  );
};

const SelectWallet = styled.div`
  display: flex;
  width: 100%;
  margin-top: 30px;
  align-items: center;
  font-size: 22px;
  font-weight: 500;
  button {
    margin-left: auto;
  }
`;

const Img = styled.div`
  width: 38px;
  height: 38px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 12px;
  border: 1px solid #e5e5e5;
  img {
    height: 14px;
  }
`;

const SelectWalletButton = styled.button`
  width: 125px;
  height: 46px;
  border: 1px solid #024953;
  border-radius: 8px;
  font-size: 18px;
  color: #024953;
  font-weight: 900;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s;
  &:hover {
    background: #f6f6f6;
  }
  i {
    margin-right: 5px;
  }
`;

export default WalletProviderModal;
