import { ethers } from 'ethers';
import { InjectedConnector } from '@web3-react/injected-connector';

export const injected = new InjectedConnector({
  supportedChainIds: [1],
});

export enum ConnectorNames {
  Injected = 'Injected',
}

export const connectorsByName: { [connectorName in ConnectorNames]: any } = {
  [ConnectorNames.Injected]: injected,
};

export const getLibrary = (provider: any): ethers.providers.Web3Provider => {
  return provider;
};
