import { useCallback } from 'react';
import { getMasterContract, getAllPools } from '../octetfi/methods';
import { useWeb3React } from '@web3-react/core';

const useAllPools = () => {
  const { chainId, library } = useWeb3React();

  const fetchAllPools = useCallback(async () => {
    if (library) {
      const masterContract = await getMasterContract(library, chainId);
      const allPools = await getAllPools(library, masterContract);
      return allPools.reverse();
    }
  }, [library, chainId]);

  return { fetchAllPools };
};

export default useAllPools;
