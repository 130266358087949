import React, { useState, useEffect } from 'react'
import CountUp from 'react-countup'
import styled from 'styled-components'

interface ValueProps {
  value: string | number
  decimals?: number
}

const Value: React.FC<ValueProps> = ({ value, decimals }) => {
  const [start, updateStart] = useState(0);
  const [end, updateEnd] = useState(0);

  useEffect(() => {
    if (typeof value === 'number') {
      updateStart(0);
      updateEnd(value)
    }
  }, [value]);

  return (
    <StyledValue>
      {typeof value == 'string' ? (
        value
      ) : (
        <CountUp
          delay={1}
          start={start}
          end={end}
          decimals={
            decimals !== undefined ? decimals : end < 0 ? 4 : end > 1e5 ? 0 : 3
          }
          duration={1}
          separator=","
        />
      )}
    </StyledValue>
  )
};

const StyledValue = styled.span``;

export default Value
