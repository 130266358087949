import styled from "styled-components";
import React, {useCallback, useContext} from "react";
import {ConfirmProps, UnstakeProps} from "../../../constants/PropsType";
import {Button} from "../../../components/Button";
import {balanceEtherToWei} from "../../../helpers";
import BigNumber from "bignumber.js";
import useUnstake from "../../../hooks/useUnstake";
import {Context} from "../../../contexts/Modals";
import {TxType} from "../../../constants/Types";


const ConfirmUnstakeModal:React.FC<ConfirmProps> = ({
    stakedBalance,
    handlePending,
    poolAddress,
    unstakeBalance,
    stakeTokenDecimals,
}) => {
    const { onDismiss } = useContext(Context);
    const { onUnstake } = useUnstake(poolAddress);
    const amountReplace = unstakeBalance.replace(/,/g,"") || '0';
    const handleUnstake = useCallback(async () => {
        try {
            const bnAmount = balanceEtherToWei(new BigNumber(amountReplace), stakeTokenDecimals);
            if (bnAmount.isGreaterThan(0) && bnAmount.isLessThanOrEqualTo(stakedBalance)) {
                await onUnstake(bnAmount.toString());
            }
        } catch (e) {
            return false;
        }
    }, [unstakeBalance, onUnstake, poolAddress]);
    return (
        <StyleFrame>
            <StyleTitle>
                <p>!</p>
                Confirmation
            </StyleTitle>
            <StyleBody>Your staked tokens and reward tokens will be deposited to your wallet when you unstake tokens from the pool.</StyleBody>
            <StyleButtonWrap>
                <Button
                    size="big" type="whiteGreen"
                    style={{marginRight: 10, height: 70, border: '1px solid #024953'}}
                    onClick={onDismiss}
                >
                    Cancel
                </Button>
                <Button
                    size="big" type="green"
                    style={{height: 70}}
                    onClick={async () => {
                        onDismiss();
                        await handlePending(TxType.UNSTAKE);
                        await handleUnstake();
                        await handlePending(TxType.NULL)
                    }}
                >
                    Unstake
                </Button>
            </StyleButtonWrap>
        </StyleFrame>
    )
}

const StyleFrame = styled.div`
    width: 512px;
    height: 340px;
    display: flex;
    flex-direction: column;
    background:#fff;
    border-radius: 20px;
    box-shadow: 0px 0px 10px 10px rgba(0, 0, 0, 0.01);
    padding: 57px 39px 54px;
    position: relative;
`;

const StyleBody = styled.div`
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 170%;
    margin-bottom: 33px;
`

const StyleButtonWrap = styled.div`
    margin-top:auto;
    display:flex;
    align-items:center;
    button {
        flex:1;
        width:50%;
    }
`;

const StyleTitle = styled.h1`
    font-style: normal;
    font-weight: bold;
    font-size: 30px;
    line-height: 35px;
    display: flex;
    margin-bottom: 14px;
    text-align: center;
    align-items: center;
    p {
        width: 28px;
        height: 28px;
        align-items: center;
        text-align: center;
        color: #E20000;
        margin-right: 10px;
        background: #FFF2F2;
        border-radius: 20px;
    }
`;

export default ConfirmUnstakeModal;