import { useCallback, useState, useEffect } from 'react';
import BigNumber from 'bignumber.js';
import { useWeb3React } from '@web3-react/core';
import { getMasterContract, getPool } from '../octetfi/methods';

const usePools = (poolAddress: string) => {
  const [poolData, setPoolData] = useState({
    stakeAddress: '',
    rewardAddress: '',
    stakeSymbol: '',
    rewardSymbol: '',
    startBlock: 0,
    endBlock: 0,
    perBlock: '0',
    leftBlock: new BigNumber(0),
    earn: new BigNumber(0),
    totalStaked: new BigNumber(0),
    totalReward: new BigNumber(0),
    initReward: new BigNumber(0),
    stakeTokenDecimals: 0,
    rewardTokenDecimals: 0,
    addUniswapV2LiquidityUrl: null,
    stakedTokenUniswapInfoUrl: null,
    rewardTokenUniswapInfoUrl: null,
    remainingTime: null,
  });

  const { account, chainId, library } = useWeb3React();

  const fetchPool = useCallback(async () => {
    if (library) {
      const masterContract = await getMasterContract(library, chainId);
      const pool = await getPool(library, masterContract, poolAddress);
      setPoolData({
        stakeAddress: pool.stakedToken,
        rewardAddress: pool.rewardToken,
        stakeSymbol: pool.stakeSymbol,
        rewardSymbol: pool.rewardSymbol,
        startBlock: pool.startBlock,
        endBlock: pool.endBlock,
        perBlock: pool.perBlock,
        leftBlock: pool.leftBlock,
        earn: pool.earn,
        totalStaked: pool.totalStaked,
        totalReward: pool.totalReward,
        initReward: pool.initReward,
        stakeTokenDecimals: pool.stakeTokenDecimals,
        rewardTokenDecimals: pool.rewardTokenDecimals,
        addUniswapV2LiquidityUrl: pool.addUniswapV2LiquidityUrl,
        stakedTokenUniswapInfoUrl: pool.stakedTokenUniswapInfoUrl,
        rewardTokenUniswapInfoUrl: pool.rewardTokenUniswapInfoUrl,
        remainingTime: pool.remainingTime,
      });
    }
  }, [library, chainId, poolAddress]);

  useEffect(() => {
    if (account && library) {
      fetchPool();
    }
  }, [poolAddress, account, library, fetchPool]);

  return { onUpdatePool: fetchPool, pool: poolData };
};

export default usePools;
