import React from 'react';
import { Routes } from 'routes';
import { Providers } from './providers';

const Disclaimer: React.FC = () => {
  return <div />;
};

const App: React.FC = () => {
  return (
    <Providers>
      <Routes />
      <Disclaimer />
    </Providers>
  );
};

export default App;
