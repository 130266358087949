import React, { useState, useCallback, useEffect } from 'react';
import styled from 'styled-components';
import BigNumber from 'bignumber.js';
import { Button } from 'components/Button';
import { StakeProps } from 'constants/PropsType';
import useAllowance from 'hooks/useAllowance';
import useApprove from 'hooks/useApprove';
import useTokenBalance from 'hooks/useTokenBalance';
import useStake from 'hooks/useStake';
import { isNull } from 'helpers/type.helper';
import { TxType } from 'constants/Types';
import { balanceWeiToEther, balanceEtherToWei } from 'helpers';
import { numberFormat, numberInputKeyPress, numberHandleChange } from 'helpers/number.helper';
import { isLessThanOrEqualTo } from 'helpers/bignumber.helper';

BigNumber.config({ EXPONENTIAL_AT: 100 });

const StakeBox: React.FC<StakeProps> = ({
  handlePending,
  pendingTx,
  poolAddress,
  stakeAddress,
  stakeSymbol,
  stakeTokenDecimals,
  leftBlock,
  addUniswapV2LiquidityUrl,
  user,
}) => {
  const [requestedApproval, setRequestedApproval] = useState(false);
  const [max, setMax] = useState('');
  const [amount, setAmount] = useState('');

  const { fetchAllowance } = useAllowance();
  const { onApprove } = useApprove();

  const { fetchTokenBalance } = useTokenBalance();
  const { onStake } = useStake(poolAddress);

  const amountReplace = amount.replace(/,/g, '') || '0';

  useEffect(() => {
    (async () => {
      const amountUnitEther = balanceEtherToWei(new BigNumber(amountReplace), stakeTokenDecimals);
      const allowance = await fetchAllowance(stakeAddress);
      if (allowance.isEqualTo(0)) {
        setRequestedApproval(false);
      } else {
        const isEnough = isLessThanOrEqualTo(amountUnitEther, allowance);
        setRequestedApproval(isEnough);
      }
    })();
  }, [user, amount, pendingTx, amountReplace, stakeTokenDecimals, fetchAllowance, stakeAddress]);

  const handleMax = async () => {
    const balance = await fetchTokenBalance(stakeAddress);
    const max = balanceWeiToEther(balance, stakeTokenDecimals);
    const result = max.toFixed(3, 1);
    setAmount(numberFormat(result));
  };

  useEffect(() => {
    (async () => {
      const balance = await fetchTokenBalance(stakeAddress);
      const maxValue = balanceWeiToEther(balance, stakeTokenDecimals);
      const result = maxValue.toFixed(3, 1);
      setMax(result.toString());
    })();
  }, [fetchTokenBalance, pendingTx, stakeAddress, stakeTokenDecimals, user]);

  const handleApprove = useCallback(async () => {
    try {
      const tx = await onApprove(stakeAddress);
      if (!tx) {
        setRequestedApproval(false);
      } else {
        setRequestedApproval(true);
      }
    } catch (e) {
      return false;
    }
  }, [onApprove, stakeAddress]);

  const handleStake = useCallback(async () => {
    try {
      const bnAmount = new BigNumber(amountReplace);
      if (bnAmount.isGreaterThan(0)) {
        await onStake(balanceEtherToWei(bnAmount, stakeTokenDecimals).toString());
        setAmount('');
      }
    } catch (e) {
      return false;
    }
  }, [amountReplace, onStake, stakeTokenDecimals]);

  return (
    <StyleRight disabled={leftBlock ? new BigNumber(leftBlock).isLessThanOrEqualTo(0) : false}>
      <StyleTitle>Stake</StyleTitle>
      <StyleInputBox>
        <Button type="gray" style={{ minWidth: 0 }} onClick={handleMax}>
          MAX
        </Button>
        <input
          type="text"
          placeholder="0"
          value={amount.toString()}
          onKeyPress={(e: any) => numberInputKeyPress(e)}
          onChange={(e: any) => {
            numberHandleChange(e, setAmount);
          }}
        />
      </StyleInputBox>
      <StyleMyAsset>
        <p>
          {numberFormat(max)}
          <b>{stakeSymbol}</b>
        </p>
      </StyleMyAsset>

      {pendingTx == TxType.APPROVE || pendingTx == TxType.STAKE ? (
        <Button size="big" fullWidth type="gray" style={{ marginTop: 'auto' }} disabled={true}>
          In Progress
        </Button>
      ) : requestedApproval ? (
        <Button
          size="big"
          fullWidth
          type="green"
          style={{ marginTop: 'auto' }}
          disabled={!requestedApproval || pendingTx !== TxType.NULL}
          onClick={async () => {
            await handlePending(TxType.STAKE);
            await handleStake();
            await handlePending(TxType.NULL);
          }}
        >
          Add Stake
        </Button>
      ) : (
        <Button
          size="big"
          fullWidth
          type="lightGreen"
          style={{ marginTop: 'auto' }}
          disabled={pendingTx !== TxType.NULL}
          onClick={async () => {
            await handlePending(TxType.APPROVE);
            await handleApprove();
            await handlePending(TxType.NULL);
          }}
        >
          Approve
        </Button>
      )}
      <StyleAddLiquidityLink hasUrl={!isNull(addUniswapV2LiquidityUrl)} href={addUniswapV2LiquidityUrl} target="_black">
        Add Liquidity {stakeSymbol}
      </StyleAddLiquidityLink>
    </StyleRight>
  );
};
const StyleRight = styled.div<{ disabled: boolean }>`
  margin-left: auto;
  width: 468px;
  height: 688px;
  display: flex;
  flex-direction: column;
  background: #fff;
  border-radius: 32px;
  box-shadow: 0px 0px 10px 10px rgba(0, 0, 0, 0.01);
  padding: 57px 39px 35px;
  position: relative;
  button:last-child {
    position: relative;
    &:after {
      content: 'Staking Pool Closed';
      display: ${(props) => (props.disabled ? 'flex' : 'none')};
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background: #e3e3e3;
      color: #000;
      z-index: 9;
      align-items: center;
      justify-content: center;
      border-radius: 14px;
      opacity: 1 !important;
    }
  }
  &:after {
    content: '';
    display: ${(props) => (props.disabled ? 'block' : 'none')};
    border-radius: 32px;
    background: #fff;
    opacity: 0.8;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 9;
  }
`;
const StyleTitle = styled.h1`
  font-size: 38px;
  margin-bottom: 33px;
`;
const StyleInputBox = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 98px;
  border: 3px solid #024953;
  border-radius: 10px;
  padding: 0 24px;
  font-size: 22px;
  font-weight: 500;
  margin-bottom: 5px;
  button {
    flex: none;
    margin-right: 10px;
  }
  input {
    flex: 1;
    text-align: right;
    width: 80%;
    min-width: 0;
    &::placeholder {
      color: #000;
    }
  }
  p {
    font-weight: 700;
    margin-left: 11px;
    flex: none;
  }
`;
const StyleResult = styled.div`
  display: flex;
  align-items: center;
  font-size: 18px;
  margin-bottom: 22px;
  padding: 0 24px;
  font-weight: 500;
  p {
    margin-left: auto;
    font-weight: normal;
  }
`;

const StyleMyAsset = styled.div`
  display: flex;
  margin-left: auto;
  text-align: right;
  p {
    display: flex;
    align-items: baseline;
    margin-top: 11px;
    font-size: 18px;
    font-weight: 500;
    b {
      font-weight: 500;
      padding-left: 5px;
      font-size: 15px;
    }
  }
`;

const StyleAddLiquidityLink = styled.a<{ hasUrl: boolean }>`
  font-size: 18px;
  font-weight: 900;
  margin-left: auto;
  margin-top: 10px;
  // margin-bottom: auto;
  color: #2d868c;
  cursor: pointer;
  display: block;
  opacity: ${(props) => (props.hasUrl ? 1 : 0)};
`;

export default StakeBox;
