import { useCallback } from 'react';
import { claimUnAllocReward, getMasterContract } from '../octetfi/methods';
import { useWeb3React } from '@web3-react/core';

const useClaimUnAllocReward = () => {
  const { account, chainId, library } = useWeb3React();

  const handleClaimUnAllocReward = useCallback(
    async (poolAddress: string) => {
      try {
        const masterContract = await getMasterContract(library, chainId);
        const tx = await claimUnAllocReward(masterContract, poolAddress, account);
        return tx;
      } catch (e) {
        return false;
      }
    },
    [account, chainId, library],
  );

  return { onClaimUnAllocReward: handleClaimUnAllocReward };
};

export default useClaimUnAllocReward;
