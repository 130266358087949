import moment from 'moment';
import BigNumber from 'bignumber.js';
import { BLOCK_SEC_TIME, BLOCK_PER_DAY } from '../helpers/constants.helper';
import { isGreaterThan, mul } from './bignumber.helper';

export const dateFormat = (date: Date | string | undefined, format?: string): string => {
  if (date) {
    let dateFormat = moment(date).format(format ? format : 'DD / MM / YYYY');
    let timeFormat = moment(date).format(format ? format : 'hh:mm A');
    return dateFormat + ' ' + timeFormat;
  }
  return '';
};

export const detachSecond = (sec: number) => {
  let remain;
  const days = Math.floor(sec / 86400);
  remain = sec % 86400;

  const hours = Math.floor(remain / 3600);
  remain = remain % 3600;

  const minutes = Math.floor(remain / 60);
  remain = remain % 60;

  const seconds = remain;
  return [days, hours, minutes, seconds];
};

export const blockHeightToDays = (height: BigNumber): any => {
  const totalBlockSeconds = mul(height, BLOCK_SEC_TIME);

  const [days, hours, minutes, seconds] = detachSecond(totalBlockSeconds.toNumber());

  if (isGreaterThan(days, 0)) return { value: days, unit: 'days' };
  if (isGreaterThan(hours, 0)) return { value: hours, unit: 'hours' };
  if (isGreaterThan(minutes, 0)) return { value: minutes, unit: 'minutes' };
  return { value: seconds, unit: 'seconds' };
  // const days = height.dividedToIntegerBy(BLOCK_PER_DAY);
  // return days.isLessThanOrEqualTo(0) ? '0' : days.toString();
};

export const termOfDay = (startDate: Date, endDate: Date): string => {
  return Math.ceil((endDate.getTime() - startDate.getTime()) / (1000 * 3600 * 24)).toString();
};

export const predictDateToBlockHeight = (date: Date, height: number): string => {
  const elapsedMSec = new BigNumber(date.getTime()).minus(Date.now());
  const elapsedSec = elapsedMSec.dividedBy(1000);

  if (elapsedSec.isLessThan(0)) {
    return new BigNumber(height).plus(elapsedSec.dividedBy(BLOCK_SEC_TIME)).toFixed(0, 1);
  } else if (elapsedSec.isEqualTo(0)) {
    return height.toString();
  } else {
    return new BigNumber(height).plus(elapsedSec.dividedBy(BLOCK_SEC_TIME)).toFixed(0, 1);
  }
};
