import { useCallback } from 'react';
import { useWeb3React } from '@web3-react/core';
import { isContractAddress } from '../octetfi/methods';

const useIsCA = () => {
  const { library } = useWeb3React();

  const handleCheckCA = useCallback(
    async (address: string) => {
      try {
        const isCA = await isContractAddress(library, address);
        return isCA;
      } catch (e) {
        return false;
      }
    },
    [library],
  );

  return { onIsCA: handleCheckCA };
};

export default useIsCA;
