import React, { useCallback, useContext } from 'react';
import styled from 'styled-components';
import { PATH } from 'constants/APP_INFO';
import { Button, Modal } from 'components';
import logo from 'assets/img/logo.png';
import { useHistory } from 'react-router';
import useModal from 'hooks/useModal';
import { shortnizeAddress } from 'helpers/address.helper';
import WalletProviderModal from './components/WalletProviderModal';
import { useWeb3React } from '@web3-react/core';
import { Context } from 'contexts/Modals';
import BannerLogoImg from '../../assets/img/banner_logo.png';
import useAuth from 'hooks/useAuth';

const Header: React.FC = ({ children }) => {
  const { account } = useWeb3React();
  const history = useHistory();
  const { onDismiss } = useContext(Context);
  const { logout } = useAuth();

  const handleSignOut = useCallback(() => {
    logout();
    onDismiss();
    history.push(PATH.MAIN);
  }, [history, logout, onDismiss]);

  const [onPresentWalletProviderModal] = useModal(<WalletProviderModal />);

  return (
    <StyleHeaderWrap>
      <StyleBanner rel="noopener noreferrer" href="https://hexlant.com" target="_blank">
        <img style={{ height: 36, marginRight: 14 }} src={BannerLogoImg} alt="octet" />
        Would you like to make a blockchain service? Start with an Octet Wallet
      </StyleBanner>
      <StyleHeader>
        <StyleContentWrap>
          {children}
          <img
            height={26}
            style={{ cursor: 'pointer' }}
            onClick={() => history.push(PATH.MAIN)}
            src={logo}
            alt="octet"
          />
          <div style={{ marginLeft: 'auto' }}>
            {account && (
              <Button
                onClick={() => history.push(PATH.CREATE)}
                style={{ marginRight: 10, background: '#DCEFF1' }}
                type="lineGreen"
              >
                <i className="xi-plus" style={{ fontWeight: 'bold', marginRight: 10 }} /> Create Staking Pool
              </Button>
            )}
            <Button
              type="green"
              // onClick={!account ? onPresentWalletProviderModal : openDisconnectModal}>
              onClick={!account ? onPresentWalletProviderModal : () => history.push(PATH.INFO)}
            >
              {!account ? 'Connect to a Wallet' : shortnizeAddress(account)}
              {account && <StyleWalletActive />}
            </Button>
          </div>
        </StyleContentWrap>
      </StyleHeader>
    </StyleHeaderWrap>
  );
};

const StyleHeaderWrap = styled.div`
  flex: none;
  width: 100%;
`;

const StyleBanner = styled.a`
  height: 65px;
  background: #024953;
  color: #fff;
  font-size: 14px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.2s;

  &:hover {
    background: #024953;
  }
`;

const StyleHeader = styled.div`
  flex: none;
  width: 100%;
  height: 64px;
  border-bottom: 1px solid rgba(3, 133, 161, 0.2);
`;

const StyleContentWrap = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  max-width: 1160px;
  margin: 0 auto;
`;

const StyleWalletActive = styled.span`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #52ff00;
  margin-left: 14px;
`;

export default Header;
