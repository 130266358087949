import { uniswapV2Client, blockClient } from './client';
import { isNull, isUndefined } from '../helpers/type.helper';

import {
  UniswapV2ETHPriceQuery,
  UniswapV2UserQuery,
  BlockNumberQuery,
  UniswapV2TokenPriceQuery,
  UniswapV2PairQuery,
} from './query';

export const getEtherPriceInUSD = async () => {
  try {
    const result = await uniswapV2Client.query({
      query: UniswapV2ETHPriceQuery,
    });

    const ethPrice = result?.data?.bundle?.ethPrice;
    if (isUndefined(ethPrice)) return null;
    return ethPrice;
  } catch (e) {
    return null;
  }
};

export const getUniswapV2LiquidityPositions = async ({ userAddress }: { userAddress: string }) => {
  try {
    const result = await uniswapV2Client.query({
      variables: {
        userAddress,
      },
      query: UniswapV2UserQuery,
    });
    const liquidityPositions = result?.data?.user?.liquidityPositions;
    if (isUndefined(liquidityPositions)) return null;
    return liquidityPositions;
  } catch (e) {
    return null;
  }
};

export const getUniswapV2TokenPrice = async ({ tokenAddress }: { tokenAddress: string }) => {
  try {
    const result = await uniswapV2Client.query({
      query: UniswapV2TokenPriceQuery,
      variables: {
        tokenAddress: tokenAddress.toLowerCase(),
      },
    });
    const derivedETH = result?.data?.token?.derivedETH;
    if (isUndefined(derivedETH)) return null;
    return derivedETH;
  } catch (e) {
    return null;
  }
};

// UniswapV2 Pair가 아닐 경우 null!
export const getUniswapV2Pair = async ({ pairAddress }: { pairAddress: string }) => {
  try {
    const result = await uniswapV2Client.query({
      query: UniswapV2PairQuery,
      variables: {
        pairAddress: pairAddress.toLowerCase(),
      },
    });
    const pair = result?.data?.pair;
    if (isUndefined(pair)) return null;
    return pair;
  } catch (e) {
    return null;
  }
};

export const getLatestBlockNumber = async () => {
  try {
    const result = await blockClient.query({
      query: BlockNumberQuery,
    });
    const number = result?.data?.blocks?.[0]?.number;
    if (isUndefined(number)) return null;
    return number;
  } catch (e) {
    return null;
  }
};
