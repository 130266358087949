import { useCallback } from 'react';
import { unstake, getMasterContract } from '../octetfi/methods';
import { useWeb3React } from '@web3-react/core';

const useUnstake = (poolAddress: string) => {
  const { account, chainId, library } = useWeb3React();

  const handleUnstake = useCallback(
    async (amount: string) => {
      try {
        const masterContract = await getMasterContract(library, chainId);
        const tx = await unstake(masterContract, poolAddress, amount, account);
        return tx;
      } catch (e) {
        return false;
      }
    },
    [account, chainId, library, poolAddress],
  );

  return { onUnstake: handleUnstake };
};

export default useUnstake;
