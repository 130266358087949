import Web3 from 'web3';
import BigNumber from 'bignumber.js';
import { ethers } from 'ethers';
import { provider } from 'web3-core';
import { Contract } from 'web3-eth-contract';
import { AbiItem } from 'web3-utils';
import OctetFiABI from './lib/abi/OctetFiMaster.json';
import ERC20ABI from './lib/abi/ERC20.json';
import { getUniswapV2Pair, getUniswapV2TokenPrice } from '../subgraph';
import { isNull } from '../helpers/type.helper';
import { formatUnitsUsingDecimals } from '../helpers/amount.helper';
import { BLOCK_PER_DAY } from '../helpers/constants.helper';

import {
  UNISWAP_V2_ADD_LIQUIDITY_URL,
  UNISWAP_V2_INFO_TOKEN_URL,
  UNISWAP_V2_INFO_PAIR_URL,
  WETH_ADDRESS,
  OCTET_FI_DEV_FEE_POINT,
} from './lib/constants';
import { blockHeightToDays } from 'helpers/time.helper';

BigNumber.config({ EXPONENTIAL_AT: 100 });

export enum ContractType {
  OCTETFI,
  TOKEN,
}

const MASTER_ADDRESS: { [chainId: number]: string } = {
  1: process.env.REACT_APP_OCTETFI_MASTER_ADDRESS_MAIN as string,
  3: process.env.REACT_APP_OCTETFI_MASTER_ADDRESS_ROPSTEN as string,
  4: process.env.REACT_APP_OCTETFI_MASTER_ADDRESS_RINKEBY as string,
};

export const toCheckSumAddress = (address: string) => {
  return new Web3().utils.toChecksumAddress(address);
};

/* 블록 높이 */
export const getBlockHeight = async (provider: provider): Promise<number> => {
  const web3 = new Web3(provider);
  const height = await web3.eth.getBlockNumber();
  return height;
};

/* 컨트랙트 생성 */
export const getContract = (provider: provider, type: ContractType, address: string): Contract => {
  const web3 = new Web3(provider);
  switch (type) {
    case ContractType.OCTETFI:
      return new web3.eth.Contract(OctetFiABI as unknown as AbiItem, address);
    case ContractType.TOKEN:
      return new web3.eth.Contract(ERC20ABI as unknown as AbiItem, address);
    default:
      throw Error('Not match type');
  }
};

/* 마스터 컨트랙트 인스턴스 */
export const getMasterContract = async (provider: provider, chainId: number): Promise<Contract> => {
  const contract = getContract(provider, ContractType.OCTETFI, MASTER_ADDRESS[chainId]);
  return contract;
};

/* 토큰 컨트랙트 인스턴스 */
export const getTokenContract = async (provider: provider, contractAddress: string): Promise<Contract> => {
  const contract = getContract(provider, ContractType.TOKEN, contractAddress);
  return contract;
};

/* 컨트랙트 주소 (ca) 여부 */
export const isContractAddress = async (provider: provider, contractAddress: string): Promise<boolean> => {
  try {
    const web3 = new Web3(provider);
    const code = await web3.eth.getCode(contractAddress);
    if (code === '0x') return false;
    return true;
  } catch (e) {
    return false;
  }
};
/* 토큰 심볼 */
export const getTokenSymbol = async (tokenContract: Contract): Promise<string> => {
  try {
    const symbol = await tokenContract.methods.symbol().call();
    return symbol;
  } catch (e) {
    return null;
  }
};

/* 토큰 잔액 */
export const getBalance = async (tokenContract: Contract, userAddress: string): Promise<BigNumber> => {
  try {
    const balance = await tokenContract.methods.balanceOf(userAddress).call();
    return new BigNumber(balance);
  } catch (e) {
    return new BigNumber(0);
  }
};

export const getDecimals = async (tokenContract: Contract): Promise<number> => {
  try {
    const decimals = await tokenContract.methods.decimals().call();
    return decimals;
  } catch (e) {
    return 0;
  }
};

/* 특정 유저의 위임 잔액  */
export const getAllowance = async (
  tokenContract: Contract,
  userAddress: string,
  chainId: number,
): Promise<BigNumber> => {
  try {
    const allowance = await tokenContract.methods.allowance(userAddress, MASTER_ADDRESS[chainId]).call();
    return new BigNumber(allowance);
  } catch (e) {
    return new BigNumber(0);
  }
};

/* 전체 풀 주소 리스트 */
export const getPoolAddresses = async (masterContract: Contract): Promise<Array<string>> => {
  const pools = await masterContract.methods.getAllPools().call();
  return pools;
};

/* 전체 풀 길이 */
export const getPoolLength = async (masterContract: Contract): Promise<string> => {
  const pools = await masterContract.methods.getAllPools().call();
  return pools.length;
};

/* 풀 정보 재정의 */
export const addPropertyPool = async (provider: provider, height: number, pool: any) => {
  let leftBlock;

  if (pool.startBlock >= height) {
    leftBlock = new BigNumber(pool.endBlock).minus(pool.startBlock);
  } else {
    leftBlock = new BigNumber(pool.endBlock).minus(height);
  }

  const [stakedTokenContract, rewardTokenContract] = await Promise.all([
    getTokenContract(provider, pool.stakedToken),
    getTokenContract(provider, pool.rewardToken),
  ]);

  const [stakeTokenDecimals, rewardTokenDecimals] = await Promise.all([
    getDecimals(stakedTokenContract),
    getDecimals(rewardTokenContract),
  ]);

  const [stakedTokenPair, rewardTokenPair] = await Promise.all([
    getUniswapV2Pair({ pairAddress: pool.stakedToken }),
    getUniswapV2Pair({ pairAddress: pool.rewardToken }),
  ]);

  const addUniswapV2LiquidityUrl = isNull(stakedTokenPair)
    ? null
    : `${UNISWAP_V2_ADD_LIQUIDITY_URL}/${
        stakedTokenPair.token0.id.toLowerCase() === WETH_ADDRESS.toLowerCase()
          ? 'ETH'
          : stakedTokenPair.token0.id.toLowerCase()
      }/${
        stakedTokenPair.token1.id.toLowerCase() === WETH_ADDRESS.toLowerCase()
          ? 'ETH'
          : stakedTokenPair.token1.id.toLowerCase()
      }`;

  const stakedTokenUniswapInfoUrl = isNull(stakedTokenPair)
    ? `${UNISWAP_V2_INFO_TOKEN_URL}/${pool.stakedToken.toLowerCase()}`
    : `${UNISWAP_V2_INFO_PAIR_URL}/${pool.stakedToken.toLowerCase()}`;

  const rewardTokenUniswapInfoUrl = isNull(rewardTokenPair)
    ? `${UNISWAP_V2_INFO_TOKEN_URL}/${pool.rewardToken.toLowerCase()}`
    : `${UNISWAP_V2_INFO_PAIR_URL}/${pool.rewardToken.toLowerCase()}`;

  const stakedTokenSymbol = !isNull(stakedTokenPair)
    ? `${stakedTokenPair.token0.symbol}-${stakedTokenPair.token1.symbol} UNI-V2 LP`
    : await getTokenSymbol(stakedTokenContract);
  const rewardTokenSymbol = !isNull(rewardTokenPair)
    ? `${rewardTokenPair.token0.symbol}-${rewardTokenPair.token1.symbol} UNI-V2 LP`
    : await getTokenSymbol(rewardTokenContract);

  const initReward = new BigNumber(pool.endBlock).minus(pool.startBlock).multipliedBy(pool.perBlock);

  const canEarnReward = new BigNumber(leftBlock).multipliedBy(new BigNumber(pool.perBlock));

  const [uniswapV2TokenPriceOfStake, uniswapV2TokenPriceOfReward] = await Promise.all([
    getUniswapV2TokenPrice({ tokenAddress: pool.stakedToken }),
    getUniswapV2TokenPrice({ tokenAddress: pool.rewardToken }),
  ]);

  const perPriceOfStake = isNull(stakedTokenPair)
    ? new BigNumber(isNull(uniswapV2TokenPriceOfStake) ? 0 : uniswapV2TokenPriceOfStake)
    : new BigNumber(stakedTokenPair.trackedReserveETH).div(stakedTokenPair.totalSupply);

  const perPriceOfReward = isNull(rewardTokenPair)
    ? new BigNumber(isNull(uniswapV2TokenPriceOfReward) ? 0 : uniswapV2TokenPriceOfReward)
    : new BigNumber(rewardTokenPair.trackedReserveETH).div(rewardTokenPair.totalSupply);

  const oneDayRewardValueInETH = new BigNumber(formatUnitsUsingDecimals(pool.perBlock, rewardTokenDecimals))
    .multipliedBy(BLOCK_PER_DAY)
    .multipliedBy(perPriceOfReward);

  const stakedValueInETH = new BigNumber(formatUnitsUsingDecimals(pool.totalStaked, stakeTokenDecimals)).multipliedBy(
    perPriceOfStake,
  );

  const dailyAPR =
    stakedValueInETH.isZero() || oneDayRewardValueInETH.isZero() || leftBlock.isLessThanOrEqualTo(0)
      ? null
      : oneDayRewardValueInETH.dividedBy(stakedValueInETH).multipliedBy(100).toFixed(3);

  const remainTimer = blockHeightToDays(leftBlock);

  return {
    remainingTime: remainTimer,
    leftBlock,
    stakeSymbol: stakedTokenSymbol,
    rewardSymbol: rewardTokenSymbol,
    totalStaked: new BigNumber(pool.totalStaked),
    initReward: new BigNumber(initReward),
    totalReward: new BigNumber(pool.totalReward),
    earn: canEarnReward.isLessThanOrEqualTo(0) ? new BigNumber(0) : new BigNumber(canEarnReward),
    dailyAPR,
    stakedTokenPrice: new BigNumber(perPriceOfStake).multipliedBy(pool.totalStaked),
    unAllocReward: new BigNumber(pool.unAllocReward),
    stakeTokenDecimals,
    rewardTokenDecimals,
    addUniswapV2LiquidityUrl,
    stakedTokenUniswapInfoUrl,
    rewardTokenUniswapInfoUrl,
  };
};

/* 특정 풀 정보 */
export const getPool = async (provider: provider, masterContract: Contract, poolAddress: string): Promise<any> => {
  const height = await getBlockHeight(provider);

  const pool = await masterContract.methods.poolByPoolAddr(poolAddress).call();
  const addProps = await addPropertyPool(provider, height, pool);
  return { ...pool, ...addProps };
};

/* */
export const getPoolAddressByCreator = async (
  masterContract: Contract,
  userAddress: string,
  poolIndex: number,
): Promise<any> => {
  return await masterContract.methods.poolsByCreator(userAddress, poolIndex).call();
};

/* 모든 풀 정보 */
export const getAllPools = async (provider: provider, masterContract: Contract): Promise<any[]> => {
  const height = await getBlockHeight(provider);
  const poolAddresses = await masterContract.methods.getAllPools().call();
  if (poolAddresses.length === 0) return [];

  /* TODO:  
     컨트랙트 상에서 더 이상 리워드 없을 시 풀 삭제 하는 기능 추가 필요 
  */
  const toFrontPoolAddresses = poolAddresses.slice(3);

  const poolInfo = await Promise.all(
    toFrontPoolAddresses.map(async (address: string) => {
      const pool = await masterContract.methods.poolByPoolAddr(address).call();
      const addProps = await addPropertyPool(provider, height, pool);

      return {
        ...pool,
        ...addProps,
      };
    }),
  );
  return poolInfo;
};

/* 특정 풀에 대한 유저 스테이크 잔액 */
export const getUser = async (
  masterContract: Contract,
  poolAddress: string,
  userAddress: string,
): Promise<BigNumber> => {
  const user = await masterContract.methods.userInfo(poolAddress, userAddress).call();
  return new BigNumber(user.stakedBalance);
};

/* 특정 풀에 대한 유저의 펜딩된 리워드 */
export const getPendingReward = async (
  masterContract: Contract,
  poolAddress: string,
  userAddress: string,
): Promise<BigNumber> => {
  const pendingReward = await masterContract.methods.getPendingRewards(poolAddress, userAddress).call();
  return new BigNumber(pendingReward);
};

/* 특정 유저의 토큰 최대 위임 */
export const approve = async (tokenContract: Contract, userAccount: string, chainId: number): Promise<any> => {
  return tokenContract.methods
    .approve(MASTER_ADDRESS[chainId], ethers.constants.MaxUint256.toString())
    .send({ from: userAccount });
};

/* 스테이킹 */
export const stake = async (
  masterContract: Contract,
  poolAddress: string,
  amount: string,
  userAccount: string,
): Promise<any> => {
  return masterContract.methods.stake(poolAddress, amount).send({ from: userAccount });
};

/* 언스테이킹 */
export const unstake = async (
  masterContract: Contract,
  poolAddress: string,
  amount: string,
  userAccount: string,
): Promise<any> => {
  return masterContract.methods.unstake(poolAddress, amount).send({ from: userAccount });
};

/* 리워드 출금 */
export const claimReward = async (masterContract: Contract, poolAddress: string, userAccount: string): Promise<any> => {
  return masterContract.methods.stake(poolAddress, 0).send({ from: userAccount });
};

/* 할당되지않은 리워드 출금 */
export const claimUnAllocReward = async (
  masterContract: Contract,
  poolAddress: string,
  userAccount: string,
): Promise<any> => {
  return masterContract.methods.withdrawUnAllocReward(poolAddress).send({ from: userAccount });
};

/* 풀 생성 */
export const createPool = async (
  masterContract: Contract,
  stakeToken: string,
  rewardToken: string,
  totalReward: string,
  startBlock: string,
  endBlock: string,
  userAccount: any,
) => {
  return masterContract.methods
    .createPool(stakeToken, rewardToken, totalReward, startBlock, endBlock)
    .send({ from: userAccount });
};

/* 생성자 account와 index로 pool 주소 가져오기 */
export const poolsByCreator = async (masterContract: Contract, userAccount: string, index: number): Promise<string> => {
  const poolsByCreator = await masterContract.methods.poolsByCreator(userAccount, index).call();
  return poolsByCreator;
};
