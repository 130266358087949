import React from "react";
import styled from 'styled-components';
import {useHistory} from "react-router";
import BackImg from 'assets/img/back.png'

const BackButton: React.FC = () => {
    const history = useHistory();
    return (
        <StyleBack onClick={() => {history.goBack()}}>
            <img src={BackImg} alt="back"/>
            Back
        </StyleBack>
    )
};

const StyleBack = styled.div`
    cursor: pointer;
    border-radius: 10px;
    display:inline-flex;
    align-items:center;
    background: #DCEFF1;
    font-size: 18px;
    font-weight: bold;
    padding: 8px 16px 9px;
    img {
        height:12px;
        margin-right: 11px;
    }
`;

export default BackButton;