import React, {useState} from "react";
import styled from 'styled-components';

interface TabProps {
    tabCont: {text:string, event: () => void}[];
    style?: any;
}

const Tab: React.FC<TabProps> = ({tabCont, style}) => {
    const [tabActive, setTabActive] = useState(0);
    return (
        <StyleTab style={style}>
            {
                tabCont.map((d, i) => (
                    <StyleTabCont
                        key={i}
                        active={i === tabActive && true}
                        onClick={() => {
                            setTabActive(i);
                            d.event();
                        }}>{d.text}</StyleTabCont>
                ))
            }
        </StyleTab>
    )
};

interface StyleTabContProps {
    active: boolean;
}

const StyleTab = styled.ul`
    display:flex;
    margin-bottom: 25px;
`;

const StyleTabCont = styled.li<StyleTabContProps>`
    border-bottom: 4px solid ${props => props.active ? '#000' : 'transparent'};
    margin-right: 37px;
    font-size: 24px;
    font-weight: bold;
    padding: 10px 0;
    letter-spacing: -0.02em;
    cursor:pointer;
    color: ${props => props.active ? '#000' : '#858585'}
`;

export default Tab;