import BigNumber from 'bignumber.js';
import { BLOCK_SEC_TIME, BLOCK_PER_DAY } from '../helpers/constants.helper';

BigNumber.config({ EXPONENTIAL_AT: 100 });

export const balanceWeiToEther = (balance: BigNumber, decimals: number): BigNumber => {
  const weiToEtherBalance = balance.dividedBy(new BigNumber(10).pow(decimals));
  return weiToEtherBalance;
};

export const balanceEtherToWei = (balance: BigNumber, decimals: number): BigNumber => {
  const etherToWeiBalance = balance.multipliedBy(new BigNumber(10).pow(decimals));
  return etherToWeiBalance;
};

export const balanceViewFormat = (balance: BigNumber, decimals: number): string => {
  const viewBalance = balance.dividedBy(new BigNumber(10).pow(decimals));
  if (viewBalance.lt(1)) {
    return viewBalance.toFixed(3).toString();
  } else {
    const splitBalance = viewBalance
      .toFixed(3)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return splitBalance;
  }
};
