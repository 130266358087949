import React from "react";
import styled from 'styled-components';
import medium from 'assets/img/medium.png';

const Footer: React.FC = () => {
    return (
        <StyleFooterWrap>
            <StyleFooter>
                <p>© Hexlant Inc.</p>
                <a href="https://medium.com/octetfi" target="_blank"><img height={14} src={medium} alt="medium"/></a>
            </StyleFooter>
        </StyleFooterWrap>
    )
};

const StyleFooterWrap = styled.div`
    flex:none;
    height: 50px;
    width:100%;
    margin-top:auto;
    border-top: 1px solid rgba(3, 133, 162, 0.1);
`;

const StyleFooter = styled.div`
    width:100%;
    height: 50px;
    border-top: 1px solid rgba(3, 133, 162, 0.1);
    display:flex;
    align-items:center;
    max-width:1160px;
    margin: 0 auto;
    width:100%;
    color:rgba(0,0,0,.2);
    font-weight:700;
    letter-spacing: -0.02em;
    font-size:18px;
    flex:none;
    a {
        margin-left:auto;
    }
`;

export default Footer;