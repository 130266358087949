import { useCallback } from 'react';
import { stake, getMasterContract } from '../octetfi/methods';
import { useWeb3React } from '@web3-react/core';

const useStake = (poolAddress: string) => {
  const { account, chainId, library } = useWeb3React();

  const handleStake = useCallback(
    async (amount: string) => {
      try {
        const masterContract = await getMasterContract(library, chainId);
        const tx = await stake(masterContract, poolAddress, amount, account);
        return tx;
      } catch (e) {
        return false;
      }
    },
    [account, library, chainId, poolAddress],
  );

  return { onStake: handleStake };
};

export default useStake;
