import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import BigNumber from 'bignumber.js';
import {useHistory} from 'react-router';
import {PATH} from 'constants/APP_INFO';
import {StakeInfoType} from 'constants/Types';
import {isNull} from '../../../helpers/type.helper';
import {balanceViewFormat} from 'helpers';
import useAllPools from 'hooks/useAllPools';
import {isLessThanOrEqualTo} from 'helpers/bignumber.helper';

const Card: React.FC<{tabIndex:boolean}> = ({
                                              tabIndex
                                            }) => {
  const history = useHistory();
  const { fetchAllPools } = useAllPools();
  const [data, setData] = useState([] as Array<StakeInfoType>);
  const [tab, setTab] = useState(tabIndex);

  useEffect(() => {
    setTab(tabIndex);
  }, [tabIndex])

  useEffect(() => {
    (async () => {
      const pools = await fetchAllPools();

      setData(
          pools.map((pool) => {
            return {
              poolAddress: pool.poolAddr,
              stakeAddress: pool.stakedToken,
              rewardAddress: pool.rewardToken,
              stakeSymbol: pool.stakeSymbol,
              rewardSymbol: pool.rewardSymbol,
              leftBlock: pool.leftBlock,
              totalStaked: pool.totalStaked,
              totalReward: pool.initReward,
              earn: pool.earn,
              dailyAPR: pool.dailyAPR,
              stakedTokenPrice: pool.stakedTokenPrice,
              startBlock: pool.startBlock,
              endBlock: pool.endBlock,
              perBlock: pool.perBlock,
              unAllocReward: pool.unAllocReward,
              updatedBlock: pool.updatedBlock,
              stakeTokenDecimals: pool.stakeTokenDecimals,
              rewardTokenDecimals: pool.rewardTokenDecimals,
              addUniswapV2LiquidityUrl: pool.addUniswapV2LiquidityUrl,
              remainingTime: pool.remainingTime,
            };
          }),
      );
    })();
  }, [fetchAllPools]);

  return (
      <React.Fragment>
        {data.filter((d) => (!tab ?
            isLessThanOrEqualTo(d.leftBlock, 0) :
            !isLessThanOrEqualTo(d.leftBlock, 0)) && d).map((d,i) =>
            (
                <StyleTr
                    key={i}
                    onClick={() => {history.push(`${PATH.STAKE}/${d.poolAddress}`, {});}}>
                  <td>
                    <b>{d.stakeSymbol}</b>
                    <br/>
                    <StyleMark finished={isLessThanOrEqualTo(d.leftBlock, 0)}>
                      {isLessThanOrEqualTo(d.leftBlock, 0)
                          ? 'Finished'
                          : `${d.remainingTime.value} ${d.remainingTime.unit} left`}
                    </StyleMark>
                  </td>
                  <td>
                    <StyleFlex>
                      <b>{balanceViewFormat(d.totalStaked, d.stakeTokenDecimals)}</b>&nbsp;
                      <span>{d.stakeSymbol}</span>
                    </StyleFlex>
                    <br/>
                    {d.stakedTokenPrice.toString() !== '0' && (
                        <StyleFlex>
                          <b>≒ {balanceViewFormat(d.stakedTokenPrice, d.stakeTokenDecimals)}</b>&nbsp;
                          <span>ETH</span>
                        </StyleFlex>
                    )}
                  </td>
                  <td><b>{d.rewardSymbol}</b></td>
                  <td>
                    <b>{isNull(d.dailyAPR) ? '-'
                        : new BigNumber(d.dailyAPR).multipliedBy(365).toString()} %</b>
                  </td>
                  <td>
                    <StyleFlex>
                      <b>{balanceViewFormat(d.earn, d.rewardTokenDecimals)}</b>&nbsp;
                      <span>{d.rewardSymbol}</span>
                    </StyleFlex>
                  </td>
                  <td>
                    <StyleFlex>
                      <b>{balanceViewFormat(d.totalReward, d.rewardTokenDecimals)}</b>&nbsp;
                      <span>{d.rewardSymbol}</span>
                    </StyleFlex>
                  </td>
                </StyleTr>
            ))}
      </React.Fragment>
  );
};


interface StyleMarkProps {
  finished: boolean;
}

const StyleTr = styled.tr`
  cursor:pointer;
  &:last-child td {
    border-bottom:none;
  }
  &:hover {
    td {
      background:#fff;
    }
  }
  td {
    line-height:1.5;
    font-size:14px;
    transition: background-color .2s;
    height:70px;
    border-bottom:1px solid #eaeaea;
    &:nth-child(1) {text-align:left;padding-left:20px}
    &:nth-child(2) {text-align:left}
    &:nth-child(3) {text-align:center}
    &:nth-child(4) {text-align:center}
    &:nth-child(5) {text-align:right}
    &:nth-child(6) {text-align:right;padding-right:20px}

    span {
      font-size:11px;
    }
  }
`;

const StyleFlex = styled.div`
  display: inline-flex;
  align-items: baseline;
`;

const StyleMark = styled.span<StyleMarkProps>`
  font-size: 10px!important;
  display: inline-block;
  font-weight: bold;
  color: ${(props) => (props.finished ? '#3f576d' : '#3197f5')};
  padding: 2px 12px;
  border:1px solid ${(props) => (props.finished ? '#3f576d' : '#3197f5')};
  border-radius: 4px;
  margin-top: 3px;
`;

export default Card;
