import React, { useCallback, useEffect, useState } from 'react';
import BigNumber from 'bignumber.js';
import { Button } from '../../../components/Button';
import { balanceEtherToWei, balanceWeiToEther } from 'helpers';
import { numberFormat, numberHandleChange, numberInputKeyPress } from 'helpers/number.helper';
import styled from 'styled-components';
import { UnstakeProps } from '../../../constants/PropsType';
import useModal from '../../../hooks/useModal'; //
import ConfirmUnstakeModal from './ConfirmUnstakeModal'; //
import { isLessThanOrEqualTo } from 'helpers/bignumber.helper';

const UnstakeTokenModal: React.FC<UnstakeProps> = ({
  stakedBalance,
  stakeSymbol,
  pendingTx,
  handlePending,
  poolAddress,
  stakeTokenDecimals,
}) => {
  //const { onUnstake } = useUnstake(poolAddress);
  const [requestedApproval, setRequestedApproval] = useState(false);
  const [max, setMax] = useState('');
  //const [pendingtx, setpendingtx] = useState(pendingTx);
  const [amount, setAmount] = useState('');
  const amountReplace = amount.replace(/,/g, '') || '0';
  const [ConfirmModal] = useModal(
    <ConfirmUnstakeModal
      stakedBalance={stakedBalance}
      handlePending={handlePending}
      poolAddress={poolAddress}
      unstakeBalance={amount}
      stakeTokenDecimals={stakeTokenDecimals}
    />,
  );
  /*
    const handleUnstake = useCallback(async () => {
        try {
            const bnAmount = balanceEtherToWei(new BigNumber(amountReplace));
            if (bnAmount.isGreaterThan(0) && bnAmount.isLessThanOrEqualTo(stakedBalance)) {
                await onUnstake(bnAmount.toString());
            }
        } catch (e) {
            return false;
        }
    }, [amount, onUnstake, poolAddress]);
    */
  const handleMax = useCallback(async () => {
    setAmount(max.toString());
  }, [max]);

  useEffect(() => {
    (async () => {
      const amountUnitEther = balanceEtherToWei(new BigNumber(amountReplace), stakeTokenDecimals);
      if (amountUnitEther.isEqualTo(0)) {
        setRequestedApproval(false);
      } else {
        const isEnough = isLessThanOrEqualTo(amountUnitEther, stakedBalance);
        setRequestedApproval(isEnough);
      }
    })();
  }, [stakedBalance, amount]);

  useEffect(() => {
    (async () => {
      const maxValue = balanceWeiToEther(stakedBalance, stakeTokenDecimals);
      const result = maxValue.toFixed(3, 1);
      setMax(result.toString());
    })();
  }, [stakedBalance]);

  return (
    <StyleFrame>
      <StyleTitle>Unstake</StyleTitle>
      <StyleInputBox>
        <Button type="gray" style={{ minWidth: 0 }} onClick={handleMax}>
          MAX
        </Button>
        <input
          type="text"
          placeholder="0"
          value={amount.toString()}
          onKeyPress={(e: any) => numberInputKeyPress(e)}
          onChange={(e: any) => {
            numberHandleChange(e, setAmount);
          }}
          autoFocus
        />
        <p>{stakeSymbol}</p>
      </StyleInputBox>
      <StyleAsset>
        Your Staked Tokens
        <span>
          <b>{numberFormat(max)}</b> {stakeSymbol}
        </span>
      </StyleAsset>
      {/*
                pendingtx == TxType.APPROVE || pendingtx == TxType.UNSTAKE
                ? <Button size="big" fullWidth type='gray' style={{marginTop: 'auto'}} disabled={true}>In Progress</Button>
                : <Button
                        size="big"
                        fullWidth type="green"
                        style={{marginTop: 'auto'}}
                        // TODO:  조건 수정
                        disabled={
                            !requestedApproval
                            || pendingtx !== TxType.NULL }
                        onClick={async () => {
                            await handlePending(TxType.UNSTAKE);
                            await setpendingtx(TxType.UNSTAKE);
                            await handleUnstake();
                            await setpendingtx(TxType.NULL);
                            await handlePending(TxType.NULL)
                        }}>
                        Confirm
                </Button>
                 */}
      <Button
        size="big"
        fullWidth
        type="green"
        style={{ marginTop: 'auto' }}
        onClick={ConfirmModal}
        disabled={!requestedApproval /*|| pendingtx !== TxType.NULL*/}
      >
        Confirm
      </Button>
    </StyleFrame>
  );
};
const StyleFrame = styled.div`
  position: absoulte;
  width: 468px;
  height: 688px;
  display: flex;
  flex-direction: column;
  background: #fff;
  border-radius: 32px;
  box-shadow: 0px 0px 10px 10px rgba(0, 0, 0, 0.01);
  padding: 57px 39px 54px;
  position: relative;
`;
const StyleTitle = styled.h1`
  font-size: 38px;
  margin-bottom: 33px;
`;
const StyleInputBox = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 98px;
  border: 3px solid #024953;
  border-radius: 10px;
  padding: 0 24px;
  font-size: 22px;
  font-weight: 500;
  margin-bottom: 30px;
  button {
    flex: none;
    margin-right: 10px;
  }
  input {
    flex: 1;
    text-align: right;
    width: 80%;
    min-width: 0;
    &::placeholder {
      color: #000;
    }
  }
  p {
    font-weight: 700;
    margin-left: 11px;
    flex: none;
  }
`;
const StyleAsset = styled.p`
    font-size:18px;
    display:flex;
    align-items:center;
    color:#003748;
    span {
        margin-left:auto;
        text-align-right;
    }
    b{
        color:#024953
    }
    padding: 0 27px;
`;
export default UnstakeTokenModal;
