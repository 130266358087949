import { useCallback } from 'react';
import { useWeb3React } from '@web3-react/core';
import { getAllowance, getTokenContract } from '../octetfi/methods';

const useAllowance = () => {
  const { account, chainId, library } = useWeb3React();

  const fetchAllowance = useCallback(
    async (tokenAddress: string) => {
      const tokenContract = await getTokenContract(library, tokenAddress);
      const allowance = await getAllowance(tokenContract, account, chainId);
      return allowance;
    },
    [account, chainId, library],
  );

  return { fetchAllowance };
};

export default useAllowance;
