import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Button, Page } from 'components';
import { Switch, useHistory, useLocation } from 'react-router-dom';
import StakingIcon01 from 'assets/img/staking-icon01.png';
import StakingIcon02 from 'assets/img/staking-icon02.png';
import StakingIcon03 from 'assets/img/staking-icon03.png';
import { PATH } from 'constants/APP_INFO';
import usePools from '../../hooks/usePools';
import { balanceViewFormat } from 'helpers';
import { blockHeightToDays } from 'helpers/time.helper';
import { isLessThanOrEqualTo } from 'helpers/bignumber.helper';
import { OCTET_FI_DEV_FEE_POINT } from 'octetfi/lib/constants';

const CreateCheck: React.FC = () => {
  const history = useHistory();
  const location: any = useLocation();
  const poolAddress = location.pathname.slice(14);

  const { pool } = usePools(poolAddress);
  const remainingTime = blockHeightToDays(pool.leftBlock);

  useEffect(() => {
    if (!poolAddress) history.push(PATH.MAIN);
  });

  return (
    <Switch>
      <Page>
        <Title>Sucessfully created your staking pool.</Title>
        <SubTitle>Check your staking pool at home. </SubTitle>
        <StyleWrap>
          <Card>
            <StyleFlex>
              <StyleAmount style={{ fontSize: '18px', fontWeight: 900 }}>
                <b style={{ fontSize: '28px' }}>{pool.stakeSymbol}</b> POOL
              </StyleAmount>
              <StyleMark>
                {isLessThanOrEqualTo(pool.leftBlock, 0) ? '-' : `${remainingTime.value} ${remainingTime.uint} left`}
              </StyleMark>
            </StyleFlex>

            <StyleText>
              <img src={StakingIcon01} alt="Earn" />
              Total Stake
            </StyleText>
            <StyleAmount>
              <b style={{ fontSize: '24px' }}>{balanceViewFormat(pool.totalStaked, pool.stakeTokenDecimals)} </b>
              {pool.stakeSymbol}{' '}
            </StyleAmount>

            <StyleFlex>
              <div style={{ width: '50%', flex: 1 }}>
                <StyleText>
                  <img src={StakingIcon02} alt="Reward" />
                  Reward token
                </StyleText>
                <StyleAmount>
                  <b>{pool.rewardSymbol}</b>
                </StyleAmount>
              </div>
              <StyleCardLine className="vertical" />
              <div style={{ width: '50%', flex: 1 }}>
                <StyleText>
                  <img src={StakingIcon03} alt="APY" />
                  APY
                </StyleText>
                <StyleAmount>
                  <b>-</b>%
                </StyleAmount>
              </div>
            </StyleFlex>

            <StyleCardLine />
            <StyleText>Total Reward</StyleText>
            <StyleAmount>
              <b>{balanceViewFormat(pool.initReward, pool.rewardTokenDecimals)}</b>
              {pool.rewardSymbol}
            </StyleAmount>
          </Card>

          <Info>
            <tbody>
              <tr>
                <th>Staking Token</th>
                <td> {pool.stakeSymbol}</td>
              </tr>
              <tr>
                <th>Reward Token</th>
                <td>{pool.rewardSymbol}</td>
              </tr>
              <tr>
                <th>Reward Amount</th>
                <td>
                  {balanceViewFormat(pool.initReward, pool.rewardTokenDecimals)} {pool.rewardSymbol}
                </td>
              </tr>
              <tr>
                <th>Fee Amount</th>
                <td>
                  {balanceViewFormat(
                    pool.totalReward.multipliedBy(OCTET_FI_DEV_FEE_POINT).dividedBy(1000),
                    pool.rewardTokenDecimals,
                  )}
                  {pool.rewardSymbol}
                </td>
              </tr>
              <tr>
                <th>Staking Period</th>
                <td>
                  {isLessThanOrEqualTo(pool.leftBlock, 0) ? '-' : `${remainingTime.value} ${remainingTime.unit} left`}
                </td>
              </tr>
              <tr>
                <th>Start Block</th>
                <td>{pool.startBlock}</td>
              </tr>
              <tr>
                <th>End Block</th>
                <td>{pool.endBlock}</td>
              </tr>
            </tbody>
          </Info>
        </StyleWrap>
        <Button
          size="big"
          type="green"
          onClick={() => history.push(PATH.MAIN)}
          style={{ width: 430, margin: '0 auto', display: 'block' }}
        >
          Home
        </Button>
      </Page>
    </Switch>
  );
};

const StyleWrap = styled.div`
  width: 783px;
  margin: 0 auto 92px;
  display: flex;
  align-items: center;
`;

const Title = styled.h1`
  font-size: 28px;
  font-weight: 500;
  margin-bottom: 10px;
  text-align: center;
`;

const SubTitle = styled.h2`
  margin-bottom: 60px;
  font-size: 18px;
  text-align: center;
  font-weight: normal;
`;

const Card = styled.div`
  background: #fff;
  flex: none;
  border: 1px solid #ebebeb;
  border-radius: 8px;
  padding: 44px 31px;
  width: 340px;
`;

const StyleFlex = styled.div`
  display: flex;
  align-items: center;
`;

const StyleAmount = styled.div`
  display: flex;
  align-items: baseline;
  font-size: 12px;
  font-weight: 500;
  b {
    font-size: 18px;
    font-weight: bold;
    padding-right: 6px;
  }
`;

const StyleText = styled.p`
  color: rgba(0, 0, 0, 0.3);
  font-size: 12px;
  margin: 25px 0 5px;
  font-weight: 500;
  display: flex;
  align-items: center;
  img {
    height: 24px;
    margin-right: 4px;
  }
`;

const StyleMark = styled.span`
  font-size: 14px;
  font-weight: bold;
  color: #3197f5;
  padding: 3px 14px;
  background: #eef7ff;
  border-radius: 4px;
  margin-left: auto;
`;

const StyleCardLine = styled.div`
  width: 100%;
  flex: none;
  height: 1px;
  background: #eaeaea;
  margin-top: 32px;
  &.vertical {
    height: 47px;
    width: 1px;
    margin: 0 22px;
    position: relative;
    top: 13px;
  }
`;

const Info = styled.table`
  flex: 1;
  margin-left: 69px;
  th {
    font-size: 18px;
    font-weight: 500;
    padding-bottom: 24px;
    text-align: left;
  }
  td {
    font-size: 16px;
    padding-bottom: 24px;
    text-align: right;
  }
`;

export default CreateCheck;
