import React from "react";
import styled from 'styled-components';
import {useLocation} from "react-router";
import mainBg from 'assets/img/main-bg.png';

const Wrap: React.FC = ({children}) => {
    const useParams = useLocation();
    return (
        <StyledWrap isMain={useParams.pathname === '/' && true}>
            {children}
        </StyledWrap>
    );
};

const StyledWrap = styled.div<{isMain: boolean}>`
    display:flex;
    align-items: center;
    flex-direction: column;
    // background: radial-gradient(80% 80% at 50% 50%, rgba(0, 230, 174, 0.4) 0%, rgba(207, 207, 207, 0) 100%);
    background: ${props => props.isMain ? 'url(' + mainBg + ') no-repeat top right #eaf9fa' : '#eaf9fa'};
    background-size: 1093px 413px;
    width:100%;
    height:100vh;
`;

export default Wrap;