import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Switch, useLocation } from 'react-router-dom';
import { Page, BackButton } from 'components';
import PoolInfo from './components/PoolInfo';
import StakeBox from './components/StakeBox';
import useUser from '../../hooks/useUser';
import useBlockHeight from 'hooks/useBlockHeight';
import { TxType } from 'constants/Types';
import usePools from '../../hooks/usePools';

const Stake: React.FC = () => {
  const [pendingTx, setPendingTx] = useState(TxType.NULL);

  const location: any = useLocation(); // Todo: 수정 필요
  const poolAddress = location.pathname.slice(7);

  const { blockHeight } = useBlockHeight();

  const { onUpdateUser, user } = useUser(poolAddress);
  const { onUpdatePool, pool } = usePools(poolAddress);

  const handlePending = async (e: TxType) => {
    setPendingTx(e);
    await onUpdateUser();
    await onUpdatePool();
  };

  useEffect(() => {
    (async () => {
      await onUpdatePool();
      await onUpdateUser();
    })();
  }, [blockHeight, onUpdatePool, onUpdateUser]);

  return (
    <Switch>
      <Page>
        <BackButton />
        <StyleWrap>
          <PoolInfo
            poolAddress={poolAddress}
            stakeAddress={pool.stakeAddress}
            rewardAddress={pool.rewardAddress}
            stakeSymbol={pool.stakeSymbol}
            rewardSymbol={pool.rewardSymbol}
            user={user}
            startBlock={pool.startBlock}
            endBlock={pool.endBlock}
            perBlock={pool.perBlock}
            leftBlock={pool.leftBlock}
            earn={pool.earn}
            totalStaked={pool.totalStaked}
            totalReward={pool.initReward}
            pendingTx={pendingTx}
            handlePending={handlePending}
            stakeTokenDecimals={pool.stakeTokenDecimals}
            rewardTokenDecimals={pool.rewardTokenDecimals}
            stakedTokenUniswapInfoUrl={pool.stakedTokenUniswapInfoUrl}
            rewardTokenUniswapInfoUrl={pool.rewardTokenUniswapInfoUrl}
            remainingTime={pool.remainingTime}
          />
          <StakeBox
            poolAddress={poolAddress}
            stakeAddress={pool.stakeAddress}
            stakeSymbol={pool.stakeSymbol}
            stakeTokenDecimals={pool.stakeTokenDecimals}
            pendingTx={pendingTx}
            handlePending={handlePending}
            user={user}
            leftBlock={pool.leftBlock}
            addUniswapV2LiquidityUrl={pool.addUniswapV2LiquidityUrl}
          />
        </StyleWrap>
      </Page>
    </Switch>
  );
};

const StyleWrap = styled.div`
  display: flex;
  align-items: center;
  width: 1160px;
  margin: 0 auto 50px;
`;

export default Stake;
